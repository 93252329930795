/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const FlexContainer = ({ as: Component = 'div', ...props }) => {
	return (
		<Component
			css={(theme) => [
				theme.row({ theme }),
				css`
					display: flex;
					flex-wrap: wrap;
				`,
			]}
			{...props}
		/>
	);
};

export default FlexContainer;
