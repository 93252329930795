/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useViewState from '../../../hooks/useViewState';
import { Typography } from '../../typography';
import LostPasswordForm from './LostPasswordForm';

const LostPasswordOverlay = () => {
	const { t } = useTranslation();
	const setAuthOverlay = useViewState(useCallback((state) => state.setAuthOverlay, []));
	const [submitted, setSubmitted] = useState(false);

	return (
		<>
			{submitted ? (
				<Typography
					as="p"
					size="l"
					css={css`
						text-align: center;
					`}
				>
					{t('auth.resetPassword.resetLinkSent')}
				</Typography>
			) : (
				<LostPasswordForm onSuccess={() => setSubmitted(true)} onToLogin={() => setAuthOverlay('login')} />
			)}
		</>
	);
};

export default LostPasswordOverlay;
