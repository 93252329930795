import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		backend: {
			loadPath: '/_resources/locales/{{lng}}.json',
			addPath: '/translation/add',
			allowMultiLoading: false,
			withCredentials: true,
		},
		lng: document.querySelector('html')?.getAttribute('lang') || 'de-CH',
		load: 'currentOnly',
		fallbackLng: 'de-CH',
		// saveMissing: true,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
