/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLostPasswordMutation } from '../../../shop';
import { FieldError, FieldLabel, FormField, Input } from '../../form';
import { FormFieldError, required } from '../../form/final-form';
import { Typography } from '../../typography';

const LostPasswordForm = ({ onSuccess = () => {}, onToLogin = () => {} }) => {
	const lostPw = useLostPasswordMutation();
	const { t } = useTranslation();

	const onSubmit = async ({ email }) => {
		try {
			await lostPw.mutateAsync({ email });
		} catch (err) {
			return { [FORM_ERROR]: [t('form.errors.unspecific')] };
		}
		onSuccess();
	};
	return (
		<Form onSubmit={onSubmit}>
			{({ handleSubmit, submitting, hasValidationErrors, submitError, hasSubmitErrors }) => (
				<form onSubmit={handleSubmit}>
					<Typography
						as="h1"
						size="l"
						weight="bold"
						css={css`
							margin-bottom: 0.75em;
						`}
					>
						{t('auth.resetPassword.reset')}
					</Typography>
					<FormField>
						<FieldLabel htmlFor="email">{t('form.fields.email')}</FieldLabel>
						<Field id="email" component={Input} name="email" validate={required(t('form.errors.required'))} />
						<FormFieldError name="email" />
					</FormField>
					{hasSubmitErrors && (
						<div
							css={css`
								text-align: center;
								margin: 0.5em 0;
							`}
						>
							<FieldError size="l" error={submitError} />
						</div>
					)}
					<div
						css={(theme) => css`
							display: flex;
							flex-direction: column-reverse;
							align-items: center;
							margin-top: var(--dimension-spacing-s);
							@media (min-width: ${theme.breakpoint.m}) {
								flex-direction: row;
								justify-content: space-between;
							}
						`}
					>
						<Typography
							as="button"
							onClick={onToLogin}
							type="button"
							size="l"
							textDecoration="underline"
							css={(theme) => [
								theme.resetButton,
								css`
									margin: 0.5em 0;
								`,
							]}
						>
							{t('auth.toLogin')}
						</Typography>
						<button
							type="submit"
							disabled={submitting || hasValidationErrors}
							css={(theme) => [
								theme.button({ theme, variant: 'white', large: true }),
								css`
									font-weight: var(--font-default-weight-regular);
								`,
							]}
						>
							{t('auth.resetPassword.reset')}
						</button>
					</div>
				</form>
			)}
		</Form>
	);
};

export default LostPasswordForm;
