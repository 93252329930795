/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Arrow = ({ onClick, dir }) => (
	<div
		onClick={onClick}
		css={(theme) => [
			theme.resetButton,
			css`
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
				z-index: 10;
				cursor: pointer;
				left: ${dir === 1 ? 'auto' : 0};
				right: ${dir === -1 ? 'auto' : 0};

				&:not(:disabled) {
					&:active,
					&:focus,
					&:hover {
						outline: none;
						& path {
							opacity: 1;
						}
					}
				}
			`,
		]}
	>
		<div
			css={(theme) => [
				theme.container({ theme }),
				css`
					width: ${theme.pxToUnit(40)};
					box-sizing: content-box;

					@media (min-width: ${theme.breakpoint.l}) {
						width: ${theme.pxToUnit(46)};
					}

					& svg {
						display: block;
						width: 100%;
						transform: scale(${-dir}, 1);
					}
				`,
			]}
		>
			<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 70 70">
				<path fill="#FFF" opacity="0.9" d="M35 70C15.67 70 0 54.33 0 35S15.67 0 35 0s35 15.67 35 35-15.67 35-35 35z" />
				<path
					fill="none"
					stroke="#000"
					strokeWidth="2"
					d="M48.86 34.284H21.503M32.787 23 21.503 34.284l11.284 11.284"
				/>
			</svg>
		</div>
	</div>
);

export default Arrow;
