/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';

import { useNavigation } from '../../api/use';
import { GotoIcon } from '../icons';
import Link from '../navigation/Link';

const ShopButton = (props) => {
	const { pages } = useNavigation();
	const shopPage = useMemo(
		() => !!pages && pages.find((p) => p.className === 'Ecomm\\Page\\MainCategoryPage'),
		[pages]
	);

	return (
		!!shopPage && (
			<Link
				css={(theme) => [
					theme.headerButton({ theme }),
					css`
						display: none;
						position: relative;
						pointer-events: all;
						padding: ${theme.pxToRem(7.5)} 1rem;
						margin-right: 0.4em;

						@media (min-width: ${theme.breakpoint.xs}) {
							display: flex;
						}

						@media (min-width: ${theme.breakpoint.l}) {
							margin-right: 0;
							padding: ${theme.pxToRem(9.5)} ${theme.pxToRem(24)};
						}
					`,
				]}
				href={shopPage.link}
				{...props}
			>
				<GotoIcon
					css={css`
						display: block;
						width: 0.6em;
						margin: 0 0.4em 0 -0.5em;
					`}
				/>
				{shopPage.menuTitle}
			</Link>
		)
	);
};

export default ShopButton;
