/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useViewState from '../../../hooks/useViewState';
import { Typography } from '../../typography';
import LoginForm from './LoginForm';

const LoginOverlay = () => {
	const closeAll = useViewState(useCallback((state) => state.closeAll, []));
	const setAuthOverlay = useViewState(useCallback((state) => state.setAuthOverlay, []));
	const { t } = useTranslation();

	return (
		<>
			<Typography
				as="h1"
				size="l"
				weight="bold"
				css={css`
					margin-bottom: 0.75em;
				`}
			>
				{t('auth.login')}
			</Typography>
			<div
				css={css`
					margin: var(--dimension-spacing-s) 0;
				`}
			>
				<LoginForm onSuccess={closeAll} onLostPassword={() => setAuthOverlay('lostpassword')} />
			</div>
			<div
				css={(theme) => css`
					margin: var(--dimension-spacing-s) 0;
					@media (min-width: ${theme.breakpoint.m}) {
						margin: var(--dimension-spacing-l) 0;
					}
				`}
			>
				<p>
					<Typography
						as="span"
						size="l"
						css={css`
							margin-right: 0.25em;
						`}
					>
						{t('auth.noAccount')}
					</Typography>
					<Typography
						as="button"
						size="l"
						textDecoration="underline"
						css={(theme) => [theme.resetButton]}
						onClick={() => setAuthOverlay('register')}
					>
						{t('auth.registerHere')}
					</Typography>
				</p>
			</div>
			<div
				css={(theme) => css`
					@media (min-width: ${theme.breakpoint.m}) {
						padding-top: var(--dimension-spacing-l);
					}
				`}
			>
				<Typography as="h2" weight="bold" size="l">
					{t('reseller.resellerInfoTitle')}
				</Typography>
				<Typography as="p" size="l">
					{t('reseller.resellerInfoText')}
				</Typography>
				<Typography
					as="button"
					size="l"
					textDecoration="underline"
					css={(theme) => theme.resetButton}
					onClick={() => setAuthOverlay('reseller-register')}
				>
					{t('reseller.requestForm')}
				</Typography>
			</div>
		</>
	);
};

export default LoginOverlay;
