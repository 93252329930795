class Cookies {
	static get(name) {
		const cookie_name = `${name}=`;
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(cookie_name) === 0) {
				return c.substring(cookie_name.length, c.length);
			}
		}
		return false;
	}

	static set(name, value, expiry_days) {
		const d = new Date();
		d.setTime(d.getTime() + expiry_days * 24 * 60 * 60 * 1000);
		const expires = `expires=${d.toUTCString()}`;
		document.cookie = `${name}=${value};${expires};path=/;SameSite=lax`;
		return this.get(name);
	}

	static delete(name) {
		this.set(name, '', 0);
	}
}

const defaults = {
	endpoint: '/api/consent',
	cookieName: 'privacy-consent-prefs',
	cookieExpiry: 180,
	attachToWindow: true,
	disabled: false,
};

export class ConsentApi {
	listeners = [];

	consent = null;

	options = null;

	attachToWindow = true;

	disabled = false;

	constructor(options = {}) {
		const { endpoint, cookieName, cookieExpiry, attachToWindow, disabled } = { ...defaults, ...options };
		this.endpoint = endpoint;
		this.cookieName = cookieName;
		this.cookieExpiry = cookieExpiry;
		this.attachToWindow = attachToWindow;
		this.disabled = disabled;

		// Avoiding Javascript doing Javascripty stuff... grrr.
		this.init = this.init.bind(this);
		this.subscribe = this.subscribe.bind(this);
		this.allows = this.allows.bind(this);
		this.allows = this.allows.bind(this);
		this.giveConsent = this.giveConsent.bind(this);
		this.revokeConsent = this.revokeConsent.bind(this);
		this.setConsent = this.setConsent.bind(this);
		this.getConsent = this.getConsent.bind(this);
		this.getOptions = this.getOptions.bind(this);

		this.init();
	}

	init() {
		if (this.attachToWindow) {
			window.flxConsent = this;
		} else if (window.flxConsent) {
			delete window.flxConsent;
		}

		const data = this.getConsent();
		this.consent = data;
		this.notify();
	}

	subscribe(fn) {
		this.listeners = [...this.listeners, fn];
		return () => {
			this.listeners = this.listeners.filter((l) => l !== fn);
		};
	}

	notify() {
		this.listeners.forEach((l) => l());
	}

	allows(name) {
		if (this.disabled) {
			return true;
		}
		if (this.consent === null) {
			return false;
		}
		return this.consent[name];
	}

	async giveConsent(answers) {
		const given = answers ?? {};
		const { options } = await this.getOptions();
		if (!options) {
			return;
		}

		const result = options.reduce((prev, curr) => {
			let value = true;
			if (curr.optIn && given[curr.value] !== undefined) {
				value = given[curr.value];
			}
			return { ...prev, [curr.value]: value };
		}, []);

		this.setConsent(result);
	}

	revokeConsent() {
		Cookies.delete(this.cookieName);

		this.consent = null;

		this.notify();
	}

	setConsent(payload) {
		Cookies.set(this.cookieName, JSON.stringify(payload), this.cookieExpiry);

		this.consent = payload;

		this.notify();
	}

	getConsent() {
		const raw = Cookies.get(this.cookieName);
		return raw ? JSON.parse(raw) : null;
	}

	get isConsentPending() {
		if (this.disabled) {
			return false;
		}
		return this.consent === null;
	}

	optionCallbacks = [];
	isFetching = false;
	getOptions() {
		if (this.options !== null) {
			return Promise.resolve(this.options);
		}
		if (this.isFetching) {
			return new Promise((resolve) => this.optionCallbacks.push(resolve));
		}
		this.isFetching = true;
		try {
			return fetch(this.endpoint)
				.then((res) => res.json())
				.then((options) => {
					this.options = options;
					this.optionCallbacks.forEach((cb) => cb(options));
					this.optionCallbacks = [];
					return options;
				});
		} catch (err) {
			return null;
		}
	}
}
