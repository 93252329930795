export const formatPrice = (value, locale = 'ch-de') => {
	let target = value || 0;
	if (typeof target !== 'number') {
		target = parseFloat(value);
	}
	// TODO: locale
	return target.toLocaleString(locale, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};
