/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useEffect, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import useApi from '../api/use';
import LoadingScreen from '../components/LoadingScreen';
import ErrorScreen from '../components/ErrorScreen';
import ErrorBoundary from '../ErrorBoundary';
const MainCategoryPage = lazy(() => import('./MainCategoryPage'));
const CategoryPage = lazy(() => import('./CategoryPage'));
const ProfilePage = lazy(() => import('./profile/ProfilePage'));
const CheckoutPage = lazy(() => import('./checkout/CheckoutPage'));
const ProductPage = lazy(() => import('./product/ProductPage'));
const HerbCategoryHolder = lazy(() => import('./HerbCategoryHolder'));
const HerbCategoryPage = lazy(() => import('./HerbCategoryPage'));
const Page = lazy(() => import('./Page'));
const PricingRequestPage = lazy(() => import('./PricingRequestPage'));
const ResellerRegisterPage = lazy(() => import('./ResellerRegisterPage'));
const ThemeTest = lazy(() => import('../components/dev/ThemeTest'));

const makeAbsoluteLink = (link) => {
	if (link === '' || link === '/') {
		return document.baseURI;
	}
	return `${document.baseURI}${link.substring(1)}`;
};

const pickComponent = (className) => {
	if (!className) {
		return null;
	}
	switch (className) {
		case 'Ecomm\\Page\\MainCategoryPage':
			return MainCategoryPage;
		case 'Ecomm\\Page\\Category':
			return CategoryPage;
		case 'Ecomm\\Page\\Profile':
			return ProfilePage;
		case 'Ecomm\\Page\\Checkout':
			return CheckoutPage;
		case 'Ecomm\\Page\\Product':
			return ProductPage;
		case 'HerbCategoryHolder':
			return HerbCategoryHolder;
		case 'HerbCategory':
			return HerbCategoryPage;
		case 'ResellerRegisterPage':
			return ResellerRegisterPage;
		case 'PricingRequestPage':
			return PricingRequestPage;
		default:
			return Page;
	}
};

export const PageWrap = () => {
	const location = useLocation();

	const {
		state: {
			page: {
				vitality: { loading, error, loaded },
				className,
				...page
			},
			site: { canonicalDomain, title },
		},
		actions: { getPage, getInitial },
		initialized,
	} = useApi();

	useEffect(() => {
		if (!initialized.current) {
			getInitial(location.pathname, location.search);
			return;
		}
		getPage(location.pathname, location.search);
	}, [initialized, location.pathname, location.search, getPage, getInitial]);

	const Comp = pickComponent(className);

	return (
		<div
			css={(theme) => css`
				position: relative;
				min-height: calc(100vh - ${theme.dimension.header.height.s});

				@media (min-width: ${theme.breakpoint.m}) {
					min-height: calc(100vh - ${theme.dimension.header.height.m});
				}

				@media (min-width: ${theme.breakpoint.l}) {
					min-height: calc(100vh - ${theme.dimension.header.height.l});
				}

				&:before {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					height: 2px;
					background: var(--color-grey2);
				}
			`}
		>
			<Routes>
				{process.env.NODE_ENV === 'development' && (
					<Route
						path="/about:theme"
						element={
							<Suspense fallback={'...'}>
								<ThemeTest />
							</Suspense>
						}
					/>
				)}
				<Route
					path="*"
					element={
						<ErrorBoundary>
							{!!loaded && (
								<Helmet titleTemplate={`%s | ${title}`}>
									<title>{page.title}</title>
									<meta name="description" content={page.description || ''} />
									<meta name="canonical" content={`${canonicalDomain}${page.$url}`} />
									{page.lang.map(({ locale, link }) => (
										<link
											key={link}
											rel="alternate"
											hreflang={locale.replace('_', '-').toLowerCase()}
											href={makeAbsoluteLink(link)}
										/>
									))}
								</Helmet>
							)}
							<Suspense fallback={<LoadingScreen />}>{!!Comp && <Comp page={page} />}</Suspense>
							{!!loading && <LoadingScreen />}
							{!!error && <ErrorScreen />}
						</ErrorBoundary>
					}
				/>
			</Routes>
		</div>
	);
};

export default memo(PageWrap);
