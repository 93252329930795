/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSubmitResellerRequestMutation } from '../../../shop';
import { FieldError, FieldLabel, FormField, Textarea } from '../../form';
import { Typography } from '../../typography';
import UserFormFields from '../user/UserFormFields';

const ResellerRegisterForm = ({ isOnPage, onSuccess = () => {}, onToLogin = () => {} }) => {
	const request = useSubmitResellerRequestMutation();
	const { t } = useTranslation();

	const onSubmit = async (data) => {
		try {
			await request.mutateAsync(data);
		} catch (err) {
			return { [FORM_ERROR]: [t('form.errors.unspecific')] };
		}
		onSuccess();
	};
	return (
		<Form onSubmit={onSubmit}>
			{({ handleSubmit, submitting, hasValidationErrors, submitError, hasSubmitErrors }) => (
				<form onSubmit={handleSubmit}>
					<UserFormFields resellerFields withBorder={isOnPage} allowCountry />
					<FormField>
						<FieldLabel htmlFor="notes">{t('reseller.notes')}</FieldLabel>
						<Field id="notes" name="notes" component={Textarea} rows="3" withBorder={isOnPage} />
					</FormField>
					{hasSubmitErrors && (
						<div
							css={css`
								text-align: center;
								margin: 0.5em 0;
							`}
						>
							<FieldError size="l" error={submitError} />
						</div>
					)}
					<div
						css={(theme) => css`
							display: flex;
							flex-direction: column-reverse;
							align-items: center;
							margin-top: var(--dimension-spacing-s);
							@media (min-width: ${theme.breakpoint.m}) {
								flex-direction: row;
								justify-content: space-between;
							}
						`}
					>
						{isOnPage ? (
							<div />
						) : (
							<Typography
								as="button"
								type="button"
								onClick={onToLogin}
								size="l"
								textDecoration="underline"
								css={(theme) => [
									theme.resetButton,
									css`
										margin: 0.5em 0;
									`,
								]}
							>
								{t('auth.toLogin')}
							</Typography>
						)}
						<button
							type="submit"
							disabled={submitting || hasValidationErrors}
							css={(theme) => [
								theme.button({ theme, variant: isOnPage ? 'filled' : 'white', large: true }),
								css`
									font-weight: var(--font-default-weight-regular);
								`,
							]}
						>
							{t('reseller.sendRequest')}
						</button>
					</div>
				</form>
			)}
		</Form>
	);
};

export default ResellerRegisterForm;
