/** @jsxImportSource @emotion/react */
import { Field } from 'react-final-form';
import FieldError from '../FieldError';

export const FormFieldError = ({ name }) => (
	<Field
		name={name}
		subscription={{ touched: true, error: true }}
		render={({ meta: { touched, error } }) => <FieldError error={touched && error ? error : null} />}
	/>
);
