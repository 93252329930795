import axios from 'axios';

const apiBase = '/ecomm/';

//
// Config
//
export const getConfig = async () => {
	const { data } = await axios.get(`${apiBase}core/`, {
		withCredentials: true,
	});

	// For better access later, we parse the units in the request
	const unitIds = [];
	const units = {};

	data.units.forEach((u) => {
		unitIds.push(u.id);
		units[u.id] = u;
	});
	return {
		...data,
		unitIds,
		units,
	};
};

//
// Products
//
export const getProducts = async () => {
	const { data } = await axios.get(`${apiBase}core/products`, {
		withCredentials: true,
	});
	return data;
};

//
// Herbs
//
export const getHerbs = async () => {
	const { data } = await axios.get(`${apiBase}extra/herbs`, {
		withCredentials: true,
	});
	return data;
};

//
// User
//
export const login = async (params) => {
	const { data } = await axios.post(`${apiBase}auth/login`, params, {
		withCredentials: true,
	});
	return data;
};

export const logout = async (params) => {
	await axios.post(`${apiBase}auth/logout`, {
		withCredentials: true,
	});
};

export const register = async (params) => {
	const { data } = await axios.post(`${apiBase}auth/register`, params, {
		withCredentials: true,
	});
	return data;
};

export const submitResellerRequest = async (params) => {
	const { data } = await axios.post(`${apiBase}reseller/submission`, params);
	return data;
};

export const submitPricingRequest = async (params) => {
	const { data } = await axios.post(`${apiBase}reseller/pricingRequest`, params);
	return data;
};

export const updateUser = async (params) => {
	const { data } = await axios.post(`${apiBase}auth/update`, params, {
		withCredentials: true,
	});
	// The phone number is sent with quotes to avoid casting to number
	if (data.phone.startsWith('"')) {
		return { ...data, phone: data.phone.substring(1, data.phone.length - 1) };
	}
	return data;
};

export const changePassword = async (params) => {
	const { data } = await axios.post(`${apiBase}auth/changePassword`, params, {
		withCredentials: true,
	});
	return data;
};

export const lostPassword = async (params) => {
	const { data } = await axios.post(`${apiBase}auth/lostPassword`, params, {
		withCredentials: true,
	});
	return data;
};

export const resetPassword = async (params) => {
	const { data } = await axios.post(`${apiBase}auth/resetPassword`, params, {
		withCredentials: true,
	});
	return data;
};

export const getUser = async () => {
	try {
		const { data } = await axios.get(`${apiBase}auth/info`, {
			withCredentials: true,
		});
		// The phone number is sent with quotes to avoid casting to number
		if (data.phone.startsWith('"')) {
			return { ...data, phone: data.phone.substring(1, data.phone.length - 1) };
		}
		return data;
	} catch (err) {
		return null;
	}
};

export const getOrders = async () => {
	const { data } = await axios({
		url: `${apiBase}auth/orders`,
		withCredentials: true,
	});
	return data;
};

export const getResellerProfileInfo = async () => {
	const { data } = await axios({
		url: `${apiBase}extra/resellerProfileInfo`,
		withCredentials: true,
	});

	return data;
};
//
// Cart & Wishlist
//
export const getCart = async () => {
	const { data } = await axios({ url: `${apiBase}cart/getCart`, withCredentials: true, method: 'GET' });
	return {
		cart: data.cart || { items: [] },
		wishlist: data.wishlist || { items: [] },
	};
};

export const setWishlist = async ({ makeId }) => {
	const { data } = await axios({
		url: `${apiBase}cart/setWishlist`,
		withCredentials: true,
		method: 'POST',
		data: { makeId },
	});
	return data;
};

export const setItem = async ({ makeId, quantity = 1, freeAmount, currency = null }) => {
	const { data } = await axios({
		url: `${apiBase}cart/setItem`,
		withCredentials: true,
		method: 'POST',
		data: { makeId, quantity, freeAmount, currency },
	});
	return data;
};

export const setItems = async ({ itemList }) => {
	const { data } = await axios({
		url: `${apiBase}cart/setItems`,
		withCredentials: true,
		method: 'POST',
		data: { itemList },
	});
	return data;
};

export const requestAvailability = async ({ formData, items }) => {
	formData['products'] = [];
	items.forEach((item) => {
		formData['products'].push({ id: item.make.id, quantity: item.quantity, name: item.make.name });
	});
	const { data } = await axios({
		url: 'api/cart/requestAvailability',
		withCredentials: true,
		method: 'POST',
		data: { formData },
	});
	return data;
};

//
// Products
//
export const getResellerInfo = async (makeId) => {
	const { data } = await axios.get(`${apiBase}reseller/info/make/${makeId}`, {
		withCredentials: true,
	});

	return data;
};

//
// Checkout
//
export const getCheckoutState = async () => {
	const { data } = await axios.get(`${apiBase}checkout/state`, {
		withCredentials: true,
	});

	return data;
};

export const setCheckoutEmail = async (email) => {
	const { data } = await axios.post(`${apiBase}checkout/setCheckoutEmail`, { email }, { withCredentials: true });

	return data;
};

export const setCheckoutContactData = async ({ email, phone }) => {
	const { data } = await axios.post(
		`${apiBase}checkout/setCheckoutContactData`,
		{ email, phone },
		{ withCredentials: true }
	);

	return data;
};

export const setUseShippingAddress = async (useShippingAddress) => {
	const { data } = await axios.post(
		`${apiBase}checkout/setUseShippingAddress`,
		{ useShippingAddress },
		{ withCredentials: true }
	);
	return data;
};

export const setCheckoutAddress = async ({ purpose, type, address }) => {
	const { data } = await axios.post(
		`${apiBase}checkout/${purpose === 'shipping' ? 'setShippingAddress' : 'setBillingAddress'}`,
		{
			type,
			address,
		},
		{ withCredentials: true }
	);
	return data;
};

export const addVoucher = async (code) => {
	const { data } = await axios.post(`${apiBase}checkout/voucher`, { code }, { withCredentials: true });
	return data;
};

export const removeVoucher = async (voucherId) => {
	const { data } = await axios.delete(`${apiBase}checkout/voucher/${voucherId}`, { withCredentials: true });
	return data;
};

export const setCheckoutData = async (inputData) => {
	const { data } = await axios.post(`${apiBase}checkout/setData`, inputData, { withCredentials: true });
	return data;
};

export const setPaymentMethod = async (method) => {
	const { data } = await axios.post(`${apiBase}checkout/setPaymentMethod`, { method }, { withCredentials: true });
	return data;
};

//
// Review
//
export const getCheckoutReview = async () => {
	const { data } = await axios.get(`${apiBase}checkout/review`);
	return data;
};

export const getPaymentOptions = async () => {
	const { data } = await axios.get(`${apiBase}checkout/paymentOptions`);
	return data?.options || [];
};

export const setPromoCode = async (code) => {
	const { data } = await axios.post(
		`${apiBase}checkout/promoCode`,
		{ code },
		{
			withCredentials: true,
		}
	);
	return data;
};

export const removePromoCode = async () => {
	const { data } = await axios.delete(`${apiBase}checkout/promoCode`, {
		withCredentials: true,
	});
	return data;
};

export const confirmCheckout = async ({ successUrl, failureUrl }) => {
	const { data } = await axios.post(
		`${apiBase}checkout/confirm`,
		{ successUrl, failureUrl },
		{
			withCredentials: true,
		}
	);
	return data;
};

export const completeCheckout = async () => {
	const { data } = await axios({
		url: `${apiBase}checkout/complete`,
		method: 'POST',
		withCredentials: true,
	});
	return data;
};

//
// Payment
//
export const onPayment = async () => {
	const { data } = await axios.post(`${apiBase}checkout/paymentComplete`, {
		withCredentials: true,
	});
	return data;
};
