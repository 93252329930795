/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useRegisterMutation } from '../../../shop';
import { FieldError, FieldLabel, FormField, Input } from '../../form';
import {
	composeValidators,
	email,
	FormFieldError,
	matchString,
	minLength,
	phoneNumber,
	required,
} from '../../form/final-form';
import { Typography } from '../../typography';
import UserFormFields from '../user/UserFormFields';

const RegisterForm = ({ isOnPage, noLogin, onSuccess = () => {}, onToLogin = () => {} }) => {
	const request = useRegisterMutation();
	const { t } = useTranslation();

	const onSubmit = async (data) => {
		try {
			await request.mutateAsync(data);
		} catch (err) {
			let message = '';
			if (err.response?.data?.message) {
				message = t([`auth.register.errors.${err.response.data.message}`, 'form.errors.unspecific']);
			} else {
				message = t('form.errors.unspecific');
			}
			return { [FORM_ERROR]: [message] };
		}
		onSuccess();
	};
	return (
		<Form
			onSubmit={onSubmit}
			validate={(values) => ({
				email: composeValidators(
					required(t('form.errors.required')),
					email(t('form.errors.invalidEmail'))
				)(values.email),
				phone: phoneNumber(t('form.errors.invalidPhone'))(values.phoneNumber),
				firstName: required(t('form.errors.required'))(values.firstName),
				lastName: required(t('form.errors.required'))(values.lastName),
				password: composeValidators(
					required(t('form.errors.required')),
					minLength(8, t('form.errors.minLength', { min: 8 }))
				)(values.password),
				passwordConfirm: composeValidators(
					required(t('form.errors.required')),
					matchString(values.password, t('form.errors.doesNotMatchX', { x: t('auth.register.password') }))
				)(values.passwordConfirm),
			})}
			initialValues={{
				country: t('countries.ch'),
				countryCode: 'ch',
			}}
		>
			{({ handleSubmit, submitting, hasValidationErrors, submitError, hasSubmitErrors }) => (
				<form onSubmit={handleSubmit}>
					<UserFormFields withBorder={isOnPage} showCountryRestriction />
					<FormField>
						<FieldLabel htmlFor="password">{t('auth.register.password')}*</FieldLabel>
						<Field id="password" component={Input} name="password" type="password" withBorder={isOnPage} />
						<FormFieldError name="password" />
					</FormField>
					<FormField>
						<FieldLabel htmlFor="passwordConfirm">{t('auth.register.passwordConfirm')}*</FieldLabel>
						<Field
							id="passwordConfirm"
							component={Input}
							name="passwordConfirm"
							type="password"
							withBorder={isOnPage}
						/>
						<FormFieldError name="passwordConfirm" />
					</FormField>
					{hasSubmitErrors && (
						<div
							css={css`
								text-align: center;
								margin: 0.5em 0;
							`}
						>
							<FieldError size="l" error={submitError} />
						</div>
					)}
					<div
						css={(theme) => css`
							display: flex;
							flex-direction: column-reverse;
							align-items: center;
							margin-top: var(--dimension-spacing-s);
							@media (min-width: ${theme.breakpoint.m}) {
								flex-direction: row;
								justify-content: space-between;
							}
						`}
					>
						{!noLogin ? (
							<Typography
								as="button"
								type="button"
								onClick={onToLogin}
								size="l"
								textDecoration="underline"
								css={(theme) => [
									theme.resetButton,
									css`
										margin: 0.5em 0;
									`,
								]}
							>
								{t('auth.toLogin')}
							</Typography>
						) : (
							<div />
						)}
						<button
							type="submit"
							disabled={submitting || hasValidationErrors}
							css={(theme) => [
								theme.button({ theme, variant: isOnPage ? 'filled' : 'white', large: true }),
								css`
									font-weight: var(--font-default-weight-regular);
								`,
							]}
						>
							{t('auth.register.register')}
						</button>
					</div>
				</form>
			)}
		</Form>
	);
};

export default RegisterForm;
