/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const Input = ({ input, meta, size = 'l', weight = 'regular', withBorder = false, ...props }) => {
	return (
		<input
			css={(theme) => [
				css`
					grid-area: input;
					appearance: none;
					background: white;
					padding: ${theme.pxToUnit(10)};
					border: ${withBorder ? '1px solid var(--color-fg)' : 'none'};
					border-radius: 99999px;
					${theme.typography.getStyle({ theme, size, weight })}

					&:not(:disabled):focus {
						outline: ${withBorder ? 'none' : `1px solid ${theme.color.primary}`};
						border: ${withBorder ? `1px solid ${theme.color.primary}` : 'none'};
						background: rgba(255, 255, 255, 0.8);
					}

					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					&[type='number'] {
						-moz-appearance: textfield;
					}
				`,
				meta &&
					meta.touched &&
					meta.error &&
					css`
						border-color: var(--color-error);
					`,
			]}
			type="text"
			{...input}
			{...props}
		/>
	);
};

export default Input;
