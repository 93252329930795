/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import FocusTrap from 'focus-trap-react';
import { useEffect, useState } from 'react';

import { useConsentOptions, useGiveConsent, useIsConsentPending } from '../hooks';
import { PrimaryButton, SecondaryButton } from './Buttons';
import { Typography } from '../../components/typography';

const ConsentPopupContent = () => {
	const consentOptions = useConsentOptions();
	const giveConsent = useGiveConsent();

	const [selectedOptions, setSelectedOptions] = useState({});

	useEffect(() => {
		if (!consentOptions) {
			return;
		}
		setSelectedOptions(consentOptions.options.reduce((prev, curr) => ({ ...prev, [curr.value]: !curr.optIn }), {}));
	}, [consentOptions]);

	if (!consentOptions) {
		return null;
	}

	const { text, options } = consentOptions;

	const accept = () => giveConsent(selectedOptions);
	const acceptAll = () =>
		giveConsent(
			options.reduce((prev, curr) => ({ ...prev, [curr.value]: true })),
			{}
		);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			css={(theme) => css`
				position: fixed;
				z-index: ${theme.zIndex.consent};
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.7);
			`}
		>
			<FocusTrap>
				<div
					css={(theme) => [
						theme.font.default.family,
						css`
							position: absolute;
							box-sizing: border-box;
							background: ${theme.color.fg};
							color: ${theme.color.bg};
							padding: ${theme.pxToUnit(30)} ${theme.pxToUnit(12)} 0.5em;
							box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
							bottom: 0;
							left: 0;
							right: 0;
							max-height: 100vh;
							overflow-y: auto;

							@media (min-width: ${theme.breakpoint.m}) {
								padding: ${theme.pxToUnit(45)};
								left: 50%;
								top: 50%;
								bottom: unset;
								right: unset;
								transform: translate(-50%, -50%);
								width: calc(100vw - 2em);
								max-width: ${theme.pxToUnit(500)};
							}
						`,
					]}
					role="dialog"
					aria-modal="false"
					aria-labelledby="consent-title"
					aria-describedby="consent-message"
				>
					<div>
						<Typography
							as="h2"
							id="consent-title"
							size="l"
							css={css`
								margin: 0 0 0.5em;
							`}
						>
							{text.title}
						</Typography>
						<Typography
							as="p"
							id="consent-message"
							size="m"
							css={css`
								margin: 0.5em 0;
							`}
						>
							{text.message}
						</Typography>
						<a
							css={(theme) => [
								theme.typography.getStyle({ theme, size: 's' }),
								css`
									color: currentColor;
									text-decoration: underline;
								`,
							]}
							href={text.privacyLink}
						>
							{text.privacyLinkLabel}
						</a>
					</div>
					<div
						css={css`
							display: flex;
							flex-direction: column;
							gap: 0.25em;
							list-style: none;
							margin: 0;
							padding: 0;
						`}
					>
						<div
							css={css`
								margin: 0.5em 0;
							`}
						>
							<Typography as="span" size="s">
								{text.types}
							</Typography>
						</div>
						<ul
							css={css`
								display: flex;
								flex-direction: column;
								gap: 0.25em;
								list-style: none;
								margin: 0;
								padding: 0;
							`}
						>
							{options.map((o) => (
								<li
									key={o.value}
									css={css`
										list-style: none;
									`}
								>
									<label
										css={css`
											display: flex;
											align-items: center;
											gap: 1em;
										`}
									>
										<div
											css={css`
												position: relative;
												width: 1.25em;
												height: 1em;
												overflow: hidden;
												display: flex;
												align-items: center;
												justify-content: flex-start;
											`}
										>
											<input
												disabled={!o.optIn}
												onChange={(e) => {
													const newVal = e.target.checked;
													setSelectedOptions((prev) => ({ ...prev, [o.value]: newVal }));
												}}
												type="checkbox"
												checked={selectedOptions[o.value] === undefined ? true : selectedOptions[o.value]}
											/>
										</div>
										<Typography as="span" size="m">
											{o.label}
										</Typography>
									</label>
									<div
										css={css`
											margin-left: 2.25em;
										`}
									>
										<Typography
											as="p"
											size="s"
											css={css`
												margin: 0;
											`}
										>
											{o.description}
										</Typography>
									</div>
								</li>
							))}
						</ul>
					</div>
					<div
						css={css`
							padding-top: 0.5em;
						`}
					>
						<div
							css={css`
								display: flex;
								gap: 1em;
								flex-direction: column;
								align-items: center;
								@media (min-width: ${400 / 16}em) {
									flex-direction: row;
									justify-content: space-between;
								}
							`}
						>
							<PrimaryButton onClick={acceptAll}>{text.acceptAll}</PrimaryButton>
							<SecondaryButton onClick={accept}>{text.acceptSelection}</SecondaryButton>
						</div>
					</div>
				</div>
			</FocusTrap>
		</motion.div>
	);
};

const ConsentPopup = () => {
	const isConsentPending = useIsConsentPending();

	return <AnimatePresence>{isConsentPending && <ConsentPopupContent />}</AnimatePresence>;
};

export default ConsentPopup;
