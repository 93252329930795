/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const FlexCol = ({ as: Component = 'div', s, m, ml, l, xl, children, ...props }) => {
	return (
		<Component
			css={(theme) => [
				theme.col({ theme }),
				css`
					flex: 1 1 auto;
					overflow: hidden;
				`,
				!!s &&
					css`
						flex-basis: calc(${s} - ${theme.dimension.gap.s});
					`,
				!!m &&
					css`
						@media (min-width: ${theme.breakpoint.m}) {
							flex-basis: calc(${m} - ${theme.dimension.gap.m});
						}
					`,
				!!l &&
					css`
						@media (min-width: ${theme.breakpoint.l}) {
							flex-basis: calc(${l} - ${theme.dimension.gap.l});
						}
					`,
				!!ml &&
					css`
						@media (min-width: ${theme.breakpoint.ml}) {
							flex-basis: calc(${ml} - ${theme.dimension.gap.m});
						}
					`,
				!!xl &&
					css`
						@media (min-width: ${theme.breakpoint.l}) {
							flex-basis: calc(${xl} - ${theme.dimension.gap.l});
						}
					`,
			]}
			{...props}
		>
			{children}
		</Component>
	);
};

export default FlexCol;
