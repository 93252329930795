/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useViewState from '../../../hooks/useViewState';
import { useGetConfig, useIsLoggedIn } from '../../../shop';
import { UserIcon } from '../../icons';
import Link from '../../navigation/Link';

const LoggedInButton = () => {
	const { data } = useGetConfig();
	const { t } = useTranslation();
	return (
		<Link
			href={data && data.routes ? data.routes.profile : '/'}
			title={t('profile.toProfile')}
			css={(theme) => [
				(theme) => theme.headerButton({ theme }),
				css`
					& > svg {
						height: ${theme.pxToRem(17)};
						fill: currentcolor;
					}
					@media (min-width: ${theme.breakpoint.m}) {
						& > svg {
							height: ${theme.pxToRem(24)};
						}
					}
				`,
			]}
		>
			<UserIcon />
		</Link>
	);
};

const NotLoggedInButton = () => {
	const { t } = useTranslation();
	const setAuthOverlay = useViewState(useCallback((state) => state.setAuthOverlay, []));
	return (
		<button onClick={() => setAuthOverlay('login')} css={(theme) => theme.headerButton({ theme })}>
			{t('auth.login')}
		</button>
	);
};

const LoginButton = () => {
	const [isLoggedIn] = useIsLoggedIn();
	return isLoggedIn ? <LoggedInButton /> : <NotLoggedInButton />;
};

export default LoginButton;
