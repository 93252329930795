/** @jsxImportSource @emotion/react */

import { css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useMatchMedia from '../../../hooks/matchMedia';
import { useAddToCart } from '../../../shop';

import Slick from '../../slideshow/Slick';
import BackgroundImage from '../../image/BackgroundImage';
import Image from '../../image/Image';
import { Typography } from '../../typography';
import ItemCountInput from '../ItemCountInput';
import MakeSelect, { MakeLabel } from '../MakeSelect';

const ProductSlider = ({ product, ...props }) => {
	return (
		<div
			css={css`
				position: absolute;
				width: 100%;
				height: 100%;

				& .slick-slider {
					--slick-dot-color: rgba(255, 255, 255, 0.5);
					--slick-dot-color-active: rgba(255, 255, 255, 0.9);
				}

				& .slick-slider,
				& .slick-list,
				& .slick-track {
					height: 100%;
				}

				& .slick-slide > div {
					height: 100%;
					display: flex;
					align-items: center;
				}
			`}
			{...props}
		>
			<Slick
				dots
				infinite
				autoplay={false}
				css={css`
					position: relative;
					z-index: 1;
				`}
			>
				{product.images.map((image) => (
					<div
						key={image.id}
						css={(theme) => css`
							position: relative;
							padding: ${theme.pxToUnit(40)};
							height: 100%;
							box-sizing: border-box;

							@media (min-width: ${theme.breakpoint.m}) {
								padding: ${theme.pxToUnit(80)};
							}
						`}
					>
						<Image
							css={css`
								height: 100%;
								padding-bottom: 0;
							`}
							fit="contain"
							noBackground
							noThumb
							noLightbox
							{...image}
						/>
					</div>
				))}
			</Slick>
			<div
				css={[
					css`
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						box-sizing: border-box;
						z-index: 0;
					`,
					!product.backgroundImage &&
						css`
							background: var(--color-grey2);
						`,
				]}
			>
				{product.backgroundImage && <BackgroundImage image={product.backgroundImage} />}
			</div>
		</div>
	);
};

const ProductContent = ({ product }) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const wideInputs = useMatchMedia(
		`((min-width: ${theme.breakpoint.xs}) and (max-width: ${theme.breakpoint.m})) or (min-width: ${theme.pxToUnit(
			1440
		)})`
	);

	const isDesktop = useMatchMedia(`(min-width: ${theme.breakpoint.m})`);

	const mainMake = product && product.makes?.length > 0 ? product.makes[0] : null;
	const [selectedMake, setSelectedMake] = useState(mainMake?.id);
	const [orderCount, setOrderCount] = useState(1);
	const [isAdding, setIsAdding] = useState(false);
	const addToCart = useAddToCart();
	const onAddToCart = async (e) => {
		e.preventDefault();
		setIsAdding(true);
		await addToCart(selectedMake, orderCount, mainMake.name);
		setIsAdding(false);
	};

	if (!mainMake) {
		return null;
	}
	return (
		<div
			css={css`
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;

				@media (min-width: ${theme.breakpoint.m}) {
					flex-direction: row;
				}
			`}
		>
			<div
				css={css`
					position: relative;
					flex: 1 1 100%;

					@media (min-width: ${theme.breakpoint.m}) {
						flex: 0 0 50%;
					}
				`}
			>
				<ProductSlider product={product} />
			</div>
			<div
				css={css`
					flex: 1 1 50%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: ${theme.pxToUnit(30)} ${theme.pxToUnit(10)};

					@media (min-width: ${theme.breakpoint.m}) {
						padding: ${theme.pxToUnit(30)};
					}

					@media (min-width: ${theme.breakpoint.l}) {
						padding: ${theme.pxToUnit(40)};
					}

					@media (min-width: ${theme.breakpoint.ml}) {
						padding: ${theme.pxToUnit(60)};
					}
				`}
			>
				<div
					css={(theme) =>
						css`
							max-width: ${theme.pxToUnit(800)};
						`
					}
				>
					<Typography
						as="h1"
						size="xl"
						weight="bold"
						css={css`
							margin: 0;
						`}
					>
						{product.title}
					</Typography>
					<Typography
						as="p"
						size="l"
						css={css`
							white-space: pre-wrap;
							margin: 0;
						`}
					>
						{product.description}
					</Typography>
					{product.herbs && product.herbs.length > 0 && (
						<div
							css={(theme) => css`
								margin-top: ${theme.pxToUnit(20)};
								@media (min-width: ${theme.breakpoint.l}) {
									margin-top: ${theme.pxToUnit(40)};
								}
							`}
						>
							<Typography as="span" size="s" weight="bold">
								{t('shop.ingredients')}
							</Typography>
							{isDesktop ? (
								<ul
									css={(theme) => css`
										display: flex;
										flex-wrap: wrap;
										list-style: none;
										margin: ${theme.pxToUnit(20)} ${theme.pxToUnit(-5)} 0;
										padding: 0;
									`}
								>
									{product.herbs.map((herb) => (
										<li
											key={herb.id}
											css={css`
												display: flex;
												flex-direction: column;
												align-items: center;
												padding: ${theme.pxToUnit(5)};
											`}
										>
											<div
												css={css`
													align-self: flex-start;
												`}
											>
												<Image
													noLightbox
													{...herb.image}
													css={(theme) => css`
														height: ${theme.pxToUnit(70)};
														width: ${theme.pxToUnit((1 / herb.image.aspect) * 70)};
														padding-bottom: 0%;
													`}
												/>
											</div>
											<Typography size="s" as="span">
												{herb.menuTitle}
											</Typography>
										</li>
									))}
								</ul>
							) : (
								<Typography
									as="span"
									size="s"
									css={css`
										display: block;
									`}
								>
									{product.herbs.map((h) => h.menuTitle).join(', ')}
								</Typography>
							)}
						</div>
					)}
					<Typography
						as="p"
						size="s"
						css={(theme) => css`
							white-space: pre-wrap;
							margin: ${theme.pxToUnit(10)} 0 0;
							@media (min-width: ${theme.breakpoint.l}) {
								margin-top: ${theme.pxToUnit(20)};
							}
						`}
					>
						{product.details}
					</Typography>
					<div
						css={css`
							display: flex;
							align-items: center;
							margin-top: ${theme.pxToUnit(20)};
							flex-wrap: wrap;
							justify-content: ${wideInputs ? 'space-between' : 'inherit'};
							@media (min-width: ${theme.breakpoint.l}) {
								margin-top: ${theme.pxToUnit(40)};
							}
						`}
					>
						<div
							css={
								wideInputs
									? css`
											flex: 0 0 auto;
									  `
									: css`
											flex: 0 0 100%;
											margin-bottom: ${theme.pxToUnit(10)};
									  `
							}
						>
							{product.makes && product.makes.length > 1 ? (
								<MakeSelect
									makes={product.makes}
									value={selectedMake}
									onChange={setSelectedMake}
									aria-label={t('shop.productVariant')}
								/>
							) : (
								<MakeLabel make={mainMake} />
							)}
						</div>
						<div
							css={css`
								display: flex;
								flex: ${wideInputs ? 0 : 1} 0 auto;
								justify-content: space-between;
							`}
						>
							<div
								css={css`
									flex: 0 0 auto;
									padding: 0 ${theme.pxToUnit(10)} 0 0;
								`}
							>
								<ItemCountInput min={1} value={orderCount} onChange={(val) => setOrderCount(val)} compact />
							</div>
							<div
								css={css`
									flex: 0 0 auto;
								`}
							>
								<button onClick={onAddToCart} css={[theme.button({ theme })]} disabled={isAdding}>
									<span
										css={css`
											display: block;
										`}
									>
										{t('shop.addToCart')}
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductContent;
