/** @jsxImportSource @emotion/react */

import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldDescription, FieldLabel, FormField, Input } from '../../form';
import { composeValidators, email, FormFieldError, phoneNumber, required } from '../../form/final-form';
import ZipCityField from '../address/ZipCityField';

export const UserFormFields = ({
	withBorder,
	resellerFields = false,
	allowCountry = false,
	showCountryRestriction = false,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<FormField>
				<FieldLabel htmlFor="email">{t('form.fields.email')}*</FieldLabel>
				<Field
					id="email"
					component={Input}
					name="email"
					validate={composeValidators(required(t('form.errors.required')), email(t('form.errors.invalidEmail')))}
					withBorder={withBorder}
				/>
				<FormFieldError name="email" />
			</FormField>
			<FormField>
				<FieldLabel htmlFor="phone">{t('form.fields.phone')}*</FieldLabel>
				<Field
					id="phone"
					component={Input}
					name="phone"
					validate={composeValidators(required(t('form.errors.required')), phoneNumber(t('form.errors.invalidPhone')))}
					withBorder={withBorder}
				/>
				<FormFieldError name="phone" />
			</FormField>
			<FormField>
				<FieldLabel htmlFor="company">
					{t('form.fields.company')}
					{resellerFields && '*'}
				</FieldLabel>
				<Field
					id="company"
					component={Input}
					name="company"
					validate={resellerFields ? required(t('form.errors.required')) : undefined}
					withBorder={withBorder}
				/>
				<FormFieldError name="company" />
			</FormField>
			<FormField>
				<FieldLabel htmlFor="firstName">{t('form.fields.firstName')}*</FieldLabel>
				<Field
					id="firstName"
					component={Input}
					name="firstName"
					validate={required(t('form.errors.required'))}
					withBorder={withBorder}
				/>
				<FormFieldError name="firstName" />
			</FormField>
			<FormField>
				<FieldLabel htmlFor="lastName">{t('form.fields.lastName')}*</FieldLabel>
				<Field
					id="lastName"
					component={Input}
					name="lastName"
					validate={required(t('form.errors.required'))}
					withBorder={withBorder}
				/>
				<FormFieldError name="lastName" />
			</FormField>
			<FormField>
				<FieldLabel htmlFor="address">{t('form.fields.address')}*</FieldLabel>
				<Field
					id="address"
					component={Input}
					name="address"
					validate={required(t('form.errors.required'))}
					withBorder={withBorder}
				/>
				<FormFieldError name="address" />
			</FormField>
			<ZipCityField withBorder={withBorder} />
			<FormField>
				<FieldLabel htmlFor="country">{t('form.fields.country')}</FieldLabel>
				<Field id="country" component={Input} name="country" withBorder={withBorder} readOnly={!allowCountry} />
				{showCountryRestriction && (
					<FieldDescription>
						{t('infos.orderNotification')}{' '}
						<a href="https://bioraselli.it" target="_blank" rel="noreferrer noopener">
							bioraselli.it
						</a>
					</FieldDescription>
				)}
				<FormFieldError name="country" />
			</FormField>
			{resellerFields && (
				<FormField>
					<FieldLabel htmlFor="website">{t('form.fields.website')}</FieldLabel>
					<Field id="website" component={Input} name="website" withBorder={withBorder} />
					<FormFieldError name="website" />
				</FormField>
			)}
		</>
	);
};

export default UserFormFields;
