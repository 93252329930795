import { useMemo } from 'react';
import { useGetConfig, useGetResellerInfo } from './api';
import { useIsLoggedIn } from './auth';
import { formatPrice } from './utils';

export const useShopUnits = () => {
	const { data: config } = useGetConfig();
	if (!config) {
		return {};
	}
	return config.units;
};

export const useResellerPrice = (makeId, fallback = null) => {
	const [isLoggedIn] = useIsLoggedIn();
	const { data } = useGetResellerInfo(makeId, { enabled: isLoggedIn });
	if (!isLoggedIn || !data) {
		return fallback;
	}
	return data.resellerPrice;
};

export const useMakePrice = (makeId, fallback) => {
	const price = useResellerPrice(makeId, fallback);
	const formatted = useMemo(() => {
		return `CHF ${formatPrice(price)}`;
	}, [price]);

	return formatted;
};
