/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback } from 'react';
import useViewState from '../../../hooks/useViewState';
import Container from '../../layout/Container';
import Link from '../../navigation/Link';
import { CenteredLoadingIcon } from '../../LoadingIcon';
import OverlayButton from '../../overlay/OverlayButton';
import OverlayRoot from '../../overlay/OverlayRoot';
import { useTranslation } from 'react-i18next';
import ProductContent from './ProductContent';
import { usePage } from '../../../api/use';
import CartNotifications from '../CartNotifications';

const ProductOverlayHeader = ({ backUrl, historyBack }) => {
	return (
		<div
			css={(theme) => css`
				pointer-events: none;
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 2;

				display: flex;
				align-items: center;
				justify-content: flex-end;

				height: ${theme.dimension.header.height.s};

				@media (min-width: ${theme.breakpoint.m}) {
					height: ${theme.dimension.header.height.m};
				}

				@media (min-width: ${theme.breakpoint.l}) {
					height: ${theme.dimension.header.height.l};
					align-items: flex-start;
					padding-top: ${theme.pxToUnit(30)};
				}
			`}
		>
			<Container
				margin={false}
				limit={false}
				css={css`
					display: flex;
					justify-content: flex-end;
					pointer-events: none;
				`}
			>
				<OverlayButton as={Link} href={backUrl} jumpHistory={historyBack ? -1 : null} />
			</Container>
		</div>
	);
};

const ProductOverlay = (props) => {
	const { placeholder, backUrl, historyBack } = useViewState(useCallback((state) => state.productOverlay, []));
	const { t } = useTranslation();
	const page = usePage();

	return (
		<>
			<CartNotifications type="global" />
			<OverlayRoot as="main" noContainer aria-label={t('overlay.product.details')} {...props}>
				<ProductOverlayHeader backUrl={backUrl} historyBack={historyBack} />
				{placeholder ? <CenteredLoadingIcon /> : <ProductContent product={page} />}
			</OverlayRoot>
		</>
	);
};

export default ProductOverlay;
