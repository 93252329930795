/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useViewState from '../../hooks/useViewState';

import { useCart, useGetConfig } from '../../shop';
import { CartIcon } from '../icons';
import Link from '../navigation/Link';
import CartNotifications from './CartNotifications';

const CartButton = (props) => {
	const { data: config } = useGetConfig();
	const cart = useCart();
	const productOverlay = useViewState(useCallback((state) => state.productOverlay, []));
	const { t } = useTranslation();
	return (
		<div
			css={css`
				position: relative;
			`}
		>
			<Link
				css={(theme) => [
					theme.headerButton({ theme }),
					css`
						position: relative;
						pointer-events: all;
						padding: ${theme.pxToRem(7.5)} 1rem;

						@media (min-width: ${theme.breakpoint.l}) {
							padding: ${theme.pxToRem(9.5)} ${theme.pxToRem(24)};
						}
					`,
				]}
				href={config ? config.routes.checkout : '/'}
				title={t('shop.cart')}
				{...props}
			>
				<div
					css={(theme) => css`
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;

						&::after {
							display: flex;
							justify-content: center;
							align-items: center;
							position: absolute;
							top: 0;
							right: 0;
							transform: translate(40%, -25%);
							background: var(--color-primaryDark);
							color: var(--color-primaryDarkContrast);
							border-radius: 9999px;
							content: '${cart.itemCount}';
							font-size: ${theme.pxToRem(7)};
							line-height: 1;
							width: ${theme.pxToRem(12)};
							height: ${theme.pxToRem(12)};

							@media (min-width: ${theme.breakpoint.l}) {
								width: ${theme.pxToRem(17)};
								height: ${theme.pxToRem(17)};
								font-size: ${theme.pxToRem(10)};
							}
						}
					`}
				>
					<CartIcon
						css={(theme) => css`
							display: block;
							width: ${theme.pxToRem(22)};

							@media (min-width: ${theme.breakpoint.l}) {
								width: ${theme.pxToRem(27.3)};
							}
						`}
					/>
				</div>
			</Link>
			{!productOverlay && <CartNotifications />}
		</div>
	);
};

export default CartButton;
