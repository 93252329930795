/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const LoadingIcon = ({ ...props }) => (
	<svg
		width="38"
		height="38"
		viewBox="0 0 38 38"
		xmlns="http://www.w3.org/2000/svg"
		css={css`
			stroke: currentColor;
			width: 1.4em;
			height: 1.4em;
		`}
	>
		<g fill="none" fillRule="evenodd">
			<g transform="translate(1 1)" strokeWidth="1">
				<circle strokeOpacity=".5" cx="18" cy="18" r="18" />
				<path d="M36 18c0-9.94-8.06-18-18-18">
					<animateTransform
						attributeName="transform"
						type="rotate"
						from="0 18 18"
						to="360 18 18"
						dur="1s"
						repeatCount="indefinite"
					/>
				</path>
			</g>
		</g>
	</svg>
);

export const CenteredLoadingIcon = ({ wrapperProps, ...props }) => (
	<div
		css={css`
			display: flex;
			justify-content: center;
			padding: var(--dimension-spacing-s);
		`}
		{...wrapperProps}
	>
		<LoadingIcon {...props} />
	</div>
);

export default LoadingIcon;
