/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldError, FieldLabel, FormField, Input } from '../../form';
import { required } from '../../form/final-form';

const ZipCityError = ({ errors, touched }) => {
	const error = useMemo(() => {
		if (touched.zip && errors.zip) {
			return errors.zip;
		}
		if (touched.city && errors.city) {
			return errors.city;
		}
		return null;
	}, [touched.zip, errors.zip, touched.city, errors.city]);

	return <FieldError error={error} />;
};

const ZipCityField = ({ withBorder }) => {
	const { t } = useTranslation();
	return (
		<FormField>
			<FieldLabel>
				{t('form.fields.zip')} / {t('form.fields.city')}*
			</FieldLabel>
			<div
				css={(theme) => css`
					grid-area: input;
					display: grid;
					grid-template-columns: ${theme.pxToUnit(150)} auto;
					column-gap: ${theme.pxToUnit(15)};
					@media (min-width: ${theme.breakpoint.m}) {
						grid-template-columns: ${theme.pxToUnit(150)} auto;
						column-gap: ${theme.pxToUnit(15)};
					}
				`}
			>
				<div>
					<Field
						aria-label={t('form.fields.zip')}
						id="zip"
						component={Input}
						name="zip"
						withBorder={withBorder}
						validate={required(t('form.errors.required'))}
						css={css`
							display: block;
							width: 100%;
						`}
					/>
				</div>
				<div>
					<Field
						aria-label={t('form.fields.city')}
						id="city"
						component={Input}
						name="city"
						withBorder={withBorder}
						validate={required(t('form.errors.required'))}
						css={css`
							display: block;
							width: 100%;
						`}
					/>
				</div>
			</div>
			<FormSpy subscription={{ errors: true, touched: true }} component={ZipCityError} />
		</FormField>
	);
};

export default ZipCityField;
