/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import { Typography } from '../../components/typography';
import { ConsentClient } from '../client';
import { SecondaryButton } from './Buttons';

const MissingConsent = (props) => {
	const [text, setText] = useState({});
	useEffect(() => {
		ConsentClient.getOptions().then((res) => setText(res.text));
	}, []);

	if (!text) {
		return null;
	}

	return (
		<div
			css={(theme) => [
				css`
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 1em;
					padding: 1em;
					border: 1px solid currentColor;
					max-width: 100%;
					background: ${theme.color.bg};
				`,
			]}
			{...props}
		>
			<Typography size="s">{text.requiresAdditionalConsent}</Typography>
			<SecondaryButton
				css={(theme) => css`
					color: ${theme.color.fg};
					background: ${theme.color.bg};
					border-color: currentColor;
				`}
				onClick={() => ConsentClient.revokeConsent()}
			>
				{text.updateConsent}
			</SecondaryButton>
		</div>
	);
};

export default MissingConsent;
