/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { forwardRef, useMemo, useRef } from 'react';
import {
	DismissButton,
	FocusScope,
	mergeProps,
	OverlayContainer,
	useButton,
	useModal,
	useOverlay,
	useOverlayPosition,
	useOverlayTrigger,
	VisuallyHidden,
} from 'react-aria';
import { useTranslation } from 'react-i18next';
import { useOverlayTriggerState } from 'react-stately';
import { usePage, useSite } from '../../api/use';
import { Typography } from '../typography';

const LanguageOverlay = forwardRef(({ children, isOpen, onClose, ...rest }, ref) => {
	const { overlayProps } = useOverlay(
		{
			onClose,
			isOpen,
			isDismissable: true,
		},
		ref
	);
	const { modalProps } = useModal();

	return (
		<FocusScope restoreFocus autoFocus>
			<div
				ref={ref}
				css={(theme) => css`
					display: flex;
					flex-direction: column;
					border: 1px solid var(--color-grey2);
					background: var(--color-bg);
					width: ${theme.pxToUnit(100)};
				`}
				{...mergeProps(overlayProps, rest, modalProps)}
			>
				{children}
				<DismissButton onDismiss={onClose} />
			</div>
		</FocusScope>
	);
});

const LanguageNavigation = (props) => {
	const { t } = useTranslation();
	const { lang, locale } = useSite();
	const { lang: pageLang } = usePage();

	// We do _NOT_ use useMenuX here, as it does not support Links as menu items.
	const state = useOverlayTriggerState({});

	const buttonRef = useRef();
	const overlayRef = useRef();

	const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'listbox' }, state, buttonRef);
	const { overlayProps: positionProps } = useOverlayPosition({
		targetRef: buttonRef,
		overlayRef,
		placement: 'bottom',
		offset: 5,
		isOpen: state.isOpen,
	});

	const { buttonProps } = useButton(
		{
			onPress: state.toggle,
		},
		buttonRef
	);

	const [currentLang, langLinks] = useMemo(() => {
		if (!lang) {
			return [];
		}
		const current = lang ? lang.find((l) => l.locale === locale) : null;
		return [
			current,
			lang
				.map((l) => {
					const pLang = pageLang.find((pl) => pl.locale === l.locale);
					return { ...l, link: pLang?.link || l.home };
				})
				.filter((ll) => ll.locale !== current?.locale),
		];
	}, [lang, locale, pageLang]);
	return (
		<div {...props}>
			<button
				{...buttonProps}
				{...triggerProps}
				ref={buttonRef}
				css={(theme) => [
					theme.headerButton({ theme }),
					css`
						text-transform: uppercase;
					`,
				]}
			>
				<VisuallyHidden>{t('nav.changeLangCurrentX', { x: currentLang?.label || '...' })}</VisuallyHidden>
				<span aria-hidden="true">{currentLang?.segment || '...'}</span>
			</button>
			<OverlayContainer>
				{state.isOpen && (
					<LanguageOverlay
						{...overlayProps}
						{...positionProps}
						ref={overlayRef}
						isOpen={state.isOpen}
						onClose={state.close}
					>
						{langLinks.map((pl) => (
							<Typography
								as="a"
								key={pl.locale}
								href={pl.link}
								title={t('nav.changeLangToX', { x: pl.label })}
								css={(theme) => css`
									text-align: center;
									padding: ${theme.pxToUnit(10)};
									&:hover,
									&:focus {
										font-weight: var(--font-default-weight-bold);
										background: var(--color-grey1);
										outline: none;
									}
								`}
								lang={pl.locale}
								hrefLang={pl.locale}
							>
								{pl.segment}
							</Typography>
						))}
					</LanguageOverlay>
				)}
			</OverlayContainer>
		</div>
	);
};

export default LanguageNavigation;
