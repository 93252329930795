/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cartEvents, useGetConfig } from '../../shop';
import { ArrowRightIcon } from '../icons';
import Link from '../navigation/Link';
import { Typography } from '../typography';

const RelativeContainer = (props) => (
	<motion.div
		initial={{ opacity: 0, top: '100%' }}
		animate={{ opacity: 1, top: 'calc(100% + 0.65em)' }}
		exit={{ opacity: 0, top: '100%' }}
		css={(theme) => css`
			position: absolute;
			z-index: var(--zIndex-cartNotification);
			left: 0;
			padding: var(--dimension-spacing-s);
			border-radius: ${theme.pxToUnit(10)};
			width: calc(100vw - 2em);
			max-width: ${theme.pxToUnit(400)};
			background: var(--color-primary);
			color: var(--color-primaryContrast);
			@media (min-width: ${theme.breakpoint.mobileNav}) {
				left: 50%;
				width: ${theme.pxToUnit(600)};
				max-width: unset;
				transform: translateX(-50%);
				padding: var(--dimension-spacing-m);
				border-radius: ${theme.pxToUnit(20)};
			}
			&::before {
				content: '';
				position: absolute;
				bottom: 100%;
				left: 0%;
				height: ${theme.pxToUnit(15)};
				width: ${theme.pxToUnit(15)};
				transform: translate(1.1em, 50%) rotate(45deg);
				background: var(--color-primary);
				@media (min-width: ${theme.breakpoint.mobileNav}) {
					left: 50%;
					transform: translate(-50%, 50%) rotate(45deg);
				}
			}
		`}
		{...props}
	/>
);

const GlobalContainer = (props) => (
	<motion.div
		initial={{ opacity: 0, top: '0' }}
		animate={{ opacity: 1, top: '1em' }}
		exit={{ opacity: 0, top: '0' }}
		css={(theme) => css`
			position: fixed;
			left: 50%;
			width: calc(100vw - 2em);
			max-width: ${theme.pxToUnit(400)};
			transform: translateX(-50%);
			z-index: var(--zIndex-overlayNotification);
			padding: var(--dimension-spacing-s);
			border-radius: ${theme.pxToUnit(10)};
			background: var(--color-primary);
			color: var(--color-primaryContrast);

			@media (min-width: ${theme.breakpoint.mobileNav}) {
				width: ${theme.pxToUnit(600)};
				max-width: unset;
				padding: var(--dimension-spacing-m);
				border-radius: ${theme.pxToUnit(20)};
			}
		`}
		{...props}
	/>
);

const CartNotifications = ({ type = 'relative' }) => {
	const { t } = useTranslation();
	const { data: config } = useGetConfig();
	const [notification, setNotification] = useState(null);

	useEffect(() => {
		let timeout = null;
		const handleEvent = (e) => {
			setNotification(e.detail);
			if (timeout) {
				clearTimeout(timeout);
			}
			timeout = setTimeout(() => {
				setNotification(null);
			}, 3000);
		};
		cartEvents.addEventListener('added', handleEvent);

		return () => {
			cartEvents.removeEventListener('added', handleEvent);
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, []);

	const message = useMemo(() => {
		if (!notification) return '';
		const {
			type,
			payload: { title },
		} = notification;
		if (type === 'added') {
			return t('shop.itemAddedToCart', { item: title });
		}
		return '';
	}, [notification, t]);
	const ContainerElement = type === 'relative' ? RelativeContainer : GlobalContainer;
	return (
		<AnimatePresence>
			{notification && (
				<ContainerElement
					key={notification.key}
					css={css`
						pointer-events: all;
					`}
				>
					<Typography as="span" size="l">
						{message}
					</Typography>
					<div
						css={(theme) => css`
							margin-top: ${theme.pxToUnit(15)};
							@media (min-width: ${theme.breakpoint.m}) {
								margin-top: ${theme.pxToUnit(30)};
							}
						`}
					>
						<Typography
							size="l"
							as={Link}
							onNavigate={() => setNotification(null)}
							href={config?.routes?.checkout || '/'}
							css={css`
								cursor: pointer;
								color: var(--color-primaryContrast);
								border-bottom: 1px solid var(--color-primaryContrast);
							`}
						>
							{t('shop.toCart')}
							<ArrowRightIcon
								css={css`
									margin-left: 1em;
									width: 1em;
									height: 1em;
								`}
							/>
						</Typography>
					</div>
				</ContainerElement>
			)}
		</AnimatePresence>
	);
};

export default CartNotifications;
