/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';

import BackgroundImage from './BackgroundImage';

const Lightbox = ({ active, rect: { left, top, width, height }, onClose, image }) => {
	useEffect(() => {
		let curr = [window.pageXOffset, window.pageYOffset];

		const onScroll = () => {
			if (Math.max(Math.abs(window.pageXOffset - curr[0]), Math.abs(window.pageYOffset - curr[1])) > 20) onClose();
		};

		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [onClose]);

	useEffect(() => {
		const onKey = (evt) => {
			if (evt.key === 'Escape') {
				onClose();
			}
		};
		window.addEventListener('keyup', onKey);
		return () => window.removeEventListener('keyup', onKey);
	}, [onClose]);

	return createPortal(
		<AnimatePresence>
			{!!active && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
					css={(theme) => css`
						background: ${theme.color.bg};
						position: fixed;
						z-index: ${theme.zIndex.lightbox};
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						cursor: zoom-out;
					`}
					role="button"
					onClick={onClose}
				>
					<motion.div
						initial={{ left, top, width, height }}
						animate={{ left: '1%', top: '1%', width: '98%', height: '98%' }}
						exit={{ left, top, width, height }}
						transition={{ duration: 0.4 }}
						css={css`
							position: absolute;
						`}
					>
						<BackgroundImage backgroundSize="contain" backgroundColor="transparent" image={image} />
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body
	);
};
export default memo(Lightbox);
