/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { VisuallyHidden } from 'react-aria';

const Dot = ({ i, ...props }) => (
	<button
		css={(theme) => [
			theme.resetButton,
			css`
				display: block;
				width: 100%;

				& > div {
					background: var(--slick-dot-color);
					margin: 1em 0;
					height: 4px;
				}

				.slick-active > & > div {
					background: var(--slick-dot-color-active);
				}
			`,
		]}
		{...props}
	>
		<VisuallyHidden>Slide: {i}</VisuallyHidden>
		<div />
	</button>
);

export default Dot;
