/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useRef } from 'react';
import { useOverlay, usePreventScroll, FocusScope, OverlayContainer, useModal } from 'react-aria';
import Container from '../layout/Container';

// Allowing the overlay to capture focus will break the CMS preview when using a Chromium based browser.
// Therefore, we have to check if the page is loaded inside an iframe and only capture focus if it isn't.
const shouldFocus = window.self === window.top;

const OverlayRoot = ({ as: Component = 'div', children, header, narrow = true, noContainer = false, ...props }) => {
	const ref = useRef();
	const { underlayProps, overlayProps } = useOverlay({ isOpen: true, isDismissable: false }, ref);
	usePreventScroll();
	const { modalProps } = useModal();
	return (
		<OverlayContainer>
			<Component
				css={css`
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: var(--zIndex-overlay);
				`}
				{...underlayProps}
			>
				<FocusScope contain={shouldFocus} restoreFocus={shouldFocus} autoFocus={shouldFocus}>
					<div
						css={css`
							width: 100%;
							height: 100%;
							margin: 0;
							padding: 0;
							background: var(--color-bg);
							color: var(--color-bgContrast);
							overflow-y: auto;
						`}
						{...overlayProps}
						{...modalProps}
						{...props}
					>
						{header}
						{noContainer ? (
							children
						) : (
							<Container
								narrow={narrow}
								css={(theme) => css`
									@media (min-width: calc(${theme.breakpoint.l} + ${theme.pxToUnit(40)})) {
										padding: var(--dimension-spacing-m) 0;
									}
								`}
							>
								{children}
							</Container>
						)}
					</div>
				</FocusScope>
			</Component>
		</OverlayContainer>
	);
};

export default OverlayRoot;
