/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Dots = (props) => {
	return (
		<ul
			css={(theme) => css`
				z-index: 10;
				list-style: none;
				margin: 0;
				margin-top: -3em;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 1 1 100%;

				& li {
					flex: 0 0 3em;
					margin: 0 0.3em;

					@media (min-width: ${theme.breakpoint.m}) {
						flex-basis: 6.25em;
					}
				}
			`}
			{...props}
		/>
	);
};

export default Dots;
