/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useViewState from '../../../hooks/useViewState';
import { Typography } from '../../typography';
import ResellerRegisterForm from './ResellerRegisterForm';

const ResellerRegisterOverlay = ({ isOnPage = false }) => {
	const { t } = useTranslation();
	const setAuthOverlay = useViewState(useCallback((state) => state.setAuthOverlay, []));
	const [submitted, setSubmitted] = useState(false);

	return (
		<>
			<Typography as="h1" weight="bold" size="l">
				{t('reseller.request')}
			</Typography>
			{!submitted && (
				<Typography as="p" size="l">
					{t('reseller.requestText')}
				</Typography>
			)}
			<div
				css={css`
					margin: var(--dimension-spacing-s) 0;
				`}
			>
				{submitted ? (
					<Typography as="p" size="l">
						{t('reseller.requestReceived')}
					</Typography>
				) : (
					<ResellerRegisterForm
						isOnPage={isOnPage}
						onSuccess={() => setSubmitted(true)}
						onToLogin={() => setAuthOverlay('login')}
					/>
				)}
			</div>
		</>
	);
};

export default ResellerRegisterOverlay;
