/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Typography } from '../typography';

const FieldDescription = ({ as: Component = 'span', ...props }) => {
	return (
		<Typography
			size="s"
			as={Component}
			css={(theme) => css`
				margin-top: 0.25em;
				padding: 0 ${theme.pxToUnit(10)};
				grid-area: error;
			`}
			{...props}
		/>
	);
};

export default FieldDescription;
