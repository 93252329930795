import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const useDelayedInView = ({ delay, conf }) => {
	const { ref, inView, entry } = useInView(conf);
	const [appeared, setAppeared] = useState(false);

	// let's debounce the IO or cancel when disappeared
	useEffect(() => {
		if (!inView) {
			setAppeared(false);
			return;
		}
		const timer = setTimeout(() => {
			setAppeared(true);
		}, delay);
		return () => clearTimeout(timer);
	}, [inView, setAppeared, delay]);

	return { ref, inView: appeared, entry };
};

export default useDelayedInView;
