/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useViewState from '../../../hooks/useViewState';
import { useGetConfig } from '../../../shop';
import Link from '../../navigation/Link';
import { Typography } from '../../typography';
import RegisterForm from './RegisterForm';

const RegisterOverlay = () => {
	const { t } = useTranslation();
	const setAuthOverlay = useViewState(useCallback((state) => state.setAuthOverlay, []));
	const [submitted, setSubmitted] = useState(false);
	const { data: config } = useGetConfig();

	return (
		<>
			<Typography as="h1" weight="bold" size="l">
				{t('auth.register.title')}
			</Typography>
			<div
				css={css`
					margin: var(--dimension-spacing-s) 0;
				`}
			>
				{submitted ? (
					<>
						<Typography as="p" size="l">
							{t('auth.register.complete')}
						</Typography>
						<div
							css={css`
								margin: var(--dimension-spacing-m) 0;
							`}
						>
							<Typography
								as={Link}
								href={config?.routes?.base || '/'}
								css={(theme) => [theme.button({ theme, variant: 'white', large: true })]}
							>
								{t('auth.register.toShop')}
							</Typography>
							<Typography
								as={Link}
								href={config?.routes?.profile || '/'}
								css={(theme) => [
									theme.button({ theme, variant: 'ghost', large: true }),
									css`
										margin-left: 0.25em;
									`,
								]}
							>
								{t('auth.register.toProfile')}
							</Typography>
						</div>
					</>
				) : (
					<RegisterForm onSuccess={() => setSubmitted(true)} onToLogin={() => setAuthOverlay('login')} />
				)}
			</div>
		</>
	);
};

export default RegisterOverlay;
