import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 1000 * 60 * 10, // 10 minutes,
			retry: false,
			staleTime: 1000 * 60 * 10,
		},
	},
});

export const QueryProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
