import { useEffect } from 'react';

import { useHasConsent } from '../consent';

export const useAnalyticsConsent = (key, gtagFn = () => {}) => {
	const allowed = useHasConsent('metrics');

	useEffect(() => {
		if (allowed) {
			gtagFn('consent', 'update', {
				[key]: 'granted',
			});
		}
	}, [key, allowed, gtagFn]);
};
