/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { forwardRef } from 'react';
import { Parser, ProcessNodeDefinitions } from 'html-to-react';

import Link from '../navigation/Link';

const Table = ({ children }) => {
	return (
		<table
			css={css`
				width: 100%;
				overflow: auto;
				border-collapse: collapse;
			`}
		>
			<tbody>{children}</tbody>
		</table>
	);
};

const nodeChildsToReact = (children) => {
	return children.map((child, i) => {
		switch (child.type) {
			case 'tag':
				return React.createElement(child.name, { key: i, ...child.attribs }, nodeChildsToReact(child.children));
			case 'text':
				return child.data;
			default:
				return child;
		}
	});
};

const parser = new Parser();
const isValidNode = () => true;
const processNodeDefinitions = new ProcessNodeDefinitions(React);

const ArrowedLink = ({ node }) => {
	return node.attribs.class && node.attribs.class.indexOf('rte-arrow-link') !== -1 ? (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlSpace="preserve"
			viewBox="0 0 21.484 17.846"
			css={css`
				display: inline-block;
				width: 0.8em;
				margin-left: 0.4em;
			`}
		>
			<path fill="none" stroke="currentColor" d="M0 8.923h20.776m-8.569 8.569 8.569-8.569L12.207.354" />
		</svg>
	) : null;
};
const processingInstructions = [
	{
		shouldProcessNode: function (node) {
			return node.name === 'a' && node.attribs.custombuttonlink === '1' && node.attribs.customclick;
		},
		processNode: function (node, children) {
			const derived = node.attribs.customclick.split('.').reduce((prev, curr) => {
				if (prev && prev[curr]) {
					return prev[curr];
				}
				return null;
			}, window);
			const handler = typeof derived === 'function' ? derived : () => {};
			return (
				// eslint-disable-next-line
				<a role="button" onClick={handler}>
					{nodeChildsToReact(children)}
				</a>
			);
		},
	},
	{
		// Custom <a> processing
		replaceChildren: false,
		shouldProcessNode: function (node) {
			return (
				node.name === 'a' &&
				node.attribs.href &&
				!node.attribs.target &&
				node.attribs.href.indexOf('://') === -1 &&
				node.attribs.href.indexOf('mailto:') !== 0 &&
				node.attribs.href.indexOf('tel:') !== 0 &&
				node.attribs.href.indexOf('/assets/') === -1
			);
		},
		processNode: function (node, children) {
			return (
				<Link href={node.attribs.href} className={node.attribs.class}>
					{node.children && nodeChildsToReact(children)}
					<ArrowedLink node={node} />
				</Link>
			);
		},
	},
	{
		// arrowed <a>
		replaceChildren: false,
		shouldProcessNode: function (node) {
			return node.name === 'a';
		},
		processNode: function (node, children) {
			return (
				<a href={node.attribs.href} className={node.attribs.class}>
					{node.children && nodeChildsToReact(children)}
					<ArrowedLink node={node} />
				</a>
			);
		},
	},
	{
		// <table>
		shouldProcessNode: function (node) {
			return node.name === 'table';
		},
		processNode: function (node, children, index) {
			// let's eliminate white spaces from rte editor
			const pureChildren = children.filter((_node) => {
				return typeof _node === 'object';
			});
			return <Table key={index}>{nodeChildsToReact(pureChildren)}</Table>;
		},
	},
	{
		// <tr>
		shouldProcessNode: function (node) {
			return node.name === 'tr';
		},
		processNode: function (node, children, index) {
			// let's eliminate white spaces from rte editor
			const pureChildren = children.filter((_node) => {
				return typeof _node === 'object';
			});
			return <tr key={index}>{nodeChildsToReact(pureChildren)}</tr>;
		},
	},
	{
		// Anything else
		shouldProcessNode: function () {
			return true;
		},
		processNode: processNodeDefinitions.processDefaultNode,
	},
];

// TODO: RTE styles
const wrap = ({ theme, size }) => css`
	> * {
		${theme.typography.getStyle({ theme, size })};
	}

	strong {
		${theme.font.default.weight.regular};
	}

	& .text-left {
		text-align: left;
	}

	& .text-right {
		text-align: right;
	}

	& .text-center {
		text-align: center;
	}

	& .text-justify {
		text-align: justify;
	}

	& > {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		blockquote,
		ul {
			font-weight: 400;

			&:first-of-type {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	& ul {
		& li {
			margin-bottom: 0.8em;
		}
	}

	& .rte-text-uppercase {
		text-transform: uppercase;
	}

	& .rte-no-margin {
		margin: 0;
	}

	& .rte-nowrap {
		white-space: nowrap;
	}

	& .rte-font-alternate {
		font-family: ${theme.font.alternate.family};

		strong {
			${theme.font.alternate.weight.regular};
		}
	}

	& .rte-font-xxl {
		${theme.typography.getStyle({ theme, size: 'xxl' })};
	}

	& .rte-font-xl {
		${theme.typography.getStyle({ theme, size: 'xl' })};
	}

	& .rte-font-l {
		${theme.typography.getStyle({ theme, size: 'l' })};
	}

	& .rte-font-m {
		${theme.typography.getStyle({ theme, size: 'm' })};
	}

	& .rte-font-s {
		${theme.typography.getStyle({ theme, size: 's' })};
	}

	& .rte-button {
		${theme.button({ theme })};
	}

	& .rte-arrow-link {
		text-decoration: none;
		display: inline-block;
		border-bottom: 1px solid currentColor;
	}
`;

const RichText = ({ content, size = 'm', ...props }, ref) => {
	return (
		<div ref={ref} css={(theme) => wrap({ theme, size })} {...props}>
			{parser.parseWithInstructions(content, isValidNode, processingInstructions)}
		</div>
	);
};

export default React.memo(forwardRef(RichText));
