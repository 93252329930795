/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { FiMenu as MenuIcon, FiX as CloseIcon } from 'react-icons/fi';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useScrollThresh from '../../hooks/useScrollThresh';
import useMatchMedia from '../../hooks/matchMedia';
import { useHomeHref, usePage, useSite } from '../../api/use';
import useViewState from '../../hooks/useViewState';

import Link from '../navigation/Link';
import Logo from './Logo';
import Container from './Container';
import DarkModeSwitch from '../dark-mode/DarkModeSwitch';
import CartButton from '../ecomm/CartButton';
import ShopButton from '../ecomm/ShopButton';
import OverlayButton from '../overlay/OverlayButton';
import Navigation from '../navigation/Navigation';
import LoginButton from '../ecomm/login/LoginButton';
import LanguageNavigation from '../navigation/LanguageNavigation';
import { FocusScope } from 'react-aria';

const HeaderTagline = ({ isShort }) => {
	const { tagline } = useSite();
	return (
		<div
			css={(theme) => css`
				flex: 1 1 auto;
				display: flex;
				max-width: ${theme.pxToUnit(385)};

				& > svg {
					border: 1px solid #fff;
					margin-right: ${theme.pxToUnit(15)};
					flex: 0 0 ${theme.pxToUnit(42)};
				}

				& > div {
					position: relative;
					flex: 1 1 auto;

					& > span {
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						${theme.typography.getStyle({ theme, weight: 'bold' })}
					}
				}
			`}
		>
			<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
				<path d="m0 0h32v32h-32z" fill="#E1251B" />
				<path d="m13 6h6v7h7v6h-7v7h-6v-7h-7v-6h7z" fill="#fff" />
			</svg>
			{!isShort && (
				<div>
					<span>{tagline}</span>
				</div>
			)}
		</div>
	);
};

const Header = () => {
	const { t } = useTranslation();
	const navOpen = useViewState(useCallback((state) => state.navOpen, []));
	const setNavOpen = useViewState(useCallback((state) => state.setNavOpen, []));
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width:${theme.breakpoint.mobileNav})`);
	const isShort = useScrollThresh({ thresh: 10 });

	const page = usePage();

	const hasPageHeader = useMemo(() => {
		return !!page?.sections?.main?.find((e) => e.className === 'PageHeaderElement');
	}, [page]);

	const homeHref = useHomeHref();

	return (
		<FocusScope contain={navOpen}>
			<div
				css={(theme) => [
					css`
						pointer-events: none;
						position: sticky;
						top: 0;
						z-index: var(--zIndex-header);

						display: flex;
						align-items: center;

						height: ${theme.dimension.header.height.s};

						@media (min-width: ${theme.breakpoint.m}) {
							height: ${theme.dimension.header.height.m};
						}

						@media (min-width: ${theme.breakpoint.l}) {
							height: ${theme.dimension.header.height.l};
							align-items: flex-start;
							padding-top: ${theme.pxToUnit(30)};
						}
					`,
					isShort
						? css`
								--color-buttonBg: var(--color-primary);
								--color-buttonBgActive: var(--color-primaryDark);
								--color-buttonFg: var(--color-bg);
								--color-buttonFgActive: var(--color-bg);
								color: var(--color-primary);
						  `
						: css`
								--color-buttonBg: var(--color-bg);
								--color-buttonBgActive: var(--color-grey1);
								--color-buttonFg: var(--color-fg);
								--color-buttonFgActive: var(--color-fg);
								color: var(--color-${hasPageHeader ? 'bg' : 'fg'});
						  `,
				]}
			>
				<Container
					margin={false}
					limit={false}
					css={(theme) => css`
						flex: 1 1 auto;
						display: flex;
						align-items: center;

						@media (min-width: ${theme.breakpoint.l}) {
							align-items: flex-start;
						}
					`}
				>
					<div
						css={css`
							position: relative;
							display: flex;
							align-items: center;
							justify-content: flex-start;
							flex: 1 1 50%;

							@media (min-width: ${theme.breakpoint.l}) {
								padding-top: ${theme.pxToUnit(16)};
								align-items: flex-start;
							}
						`}
					>
						{isDesktop ? (
							<HeaderTagline isShort={isShort} />
						) : (
							<Fragment>
								<ShopButton
									css={css`
										pointer-events: all;
										z-index: var(--zIndex-headerNav);
									`}
								/>
								<CartButton
									css={css`
										pointer-events: all;
										z-index: var(--zIndex-headerNav);
									`}
								/>
							</Fragment>
						)}
					</div>
					<div
						css={(theme) => css`
							display: flex;
							align-items: center;
							flex: 0 0 auto;
							z-index: var(--zIndex-headerNav);

							@media (min-width: ${theme.breakpoint.mobileNav}) {
								z-index: var(--zIndex-header);
								align-items: flex-start;
								padding-top: ${theme.pxToUnit(12)};
							}
						`}
					>
						<Link
							href={homeHref}
							title="Homepage"
							css={css`
								pointer-events: all;
							`}
						>
							<Logo
								isShort={isShort}
								css={(theme) => css`
									color: ${navOpen ? '#FFF' : 'currentColor'};
									width: ${theme.dimension.header.logoWidth.s};

									@media (min-width: ${theme.breakpoint.l}) {
										width: ${theme.dimension.header.logoWidth.m};
									}

									@media (min-width: ${theme.breakpoint.ml}) {
										width: ${theme.dimension.header.logoWidth.l};
									}
								`}
							/>
						</Link>
					</div>
					<div
						css={(theme) => css`
							display: flex;
							align-items: center;
							justify-content: flex-end;
							flex: 1 1 50%;
							z-index: var(--zIndex-headerNav);

							& > button,
							& > a,
							& > * {
								margin-left: ${theme.pxToRem(15)};
							}
						`}
					>
						{isDesktop && (
							<>
								<ShopButton />
								<CartButton />
								<LoginButton />
								<LanguageNavigation />
							</>
						)}
						<OverlayButton
							icon={navOpen ? <CloseIcon /> : <MenuIcon />}
							label={t(`nav.${navOpen ? 'close' : 'open'}`)}
							onClick={() => setNavOpen(!navOpen)}
						/>
					</div>
					<Navigation enabled={navOpen} onChange={() => setNavOpen(!navOpen)} />
				</Container>
			</div>
		</FocusScope>
	);
};

export default Header;
