/** @jsxImportSource @emotion/react */
import { useMatch } from 'react-router';
import Link from './Link';
import { Typography } from '../typography/';

const NavLink = ({ page, ...props }) => {
	const isCurrent = useMatch(page.link);
	return (
		<Typography as={Link} href={page.link} textDecoration={isCurrent ? 'underline' : 'inherit'} {...props}>
			{page.menuTitle}
		</Typography>
	);
};

export default NavLink;
