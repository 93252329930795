import { useCallback, useEffect, useState } from 'react';
import { ConsentClient } from './client';

const selectConsent = () => ConsentClient.consent;
export const useConsent = () => {
	const [consent, setConsent] = useState(selectConsent);

	useEffect(() => {
		const unsubscribe = ConsentClient.subscribe(() => setConsent(selectConsent()));
		return () => {
			unsubscribe();
		};
	}, []);

	return consent;
};

const selectAllows = (key) => () => Boolean(ConsentClient.allows(key));
export const useHasConsent = (key) => {
	const [allows, setAllows] = useState(selectAllows(key));

	useEffect(() => {
		const unsubscribe = ConsentClient.subscribe(() => setAllows(selectAllows(key)()));
		return () => {
			unsubscribe();
		};
	}, [key]);
	return allows;
};

const selectPending = () => ConsentClient.isConsentPending;
export const useIsConsentPending = () => {
	const [isConsentPending, setIsConsentPending] = useState(selectPending);

	useEffect(() => {
		const unsubscribe = ConsentClient.subscribe(() => setIsConsentPending(selectPending()));
		return () => {
			unsubscribe();
		};
	}, []);

	return isConsentPending;
};

export const useGiveConsent = () => {
	const give = useCallback((permissions) => {
		ConsentClient.giveConsent(permissions);
	}, []);
	return give;
};

export const useConsentOptions = () => {
	const [options, setOptions] = useState();

	useEffect(() => {
		ConsentClient.getOptions().then((data) => setOptions(data));
	}, []);

	return options;
};
