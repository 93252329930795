/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import Select, { components } from 'react-select';
import { useMakePrice, useShopUnits } from '../../shop';
import { ChevronIcon } from '../icons';

export const MakeLabel = ({ make, size = 'm', ...props }) => {
	const units = useShopUnits();
	const price = useMakePrice(make.id, make.price);
	return (
		<div
			css={(theme) => [
				theme.typography.getStyle({ theme, size }),
				css`
					white-space: nowrap;
					text-align: center;
					display: flex;
					justify-content: space-between;
				`,
			]}
			{...props}
		>
			<span
				css={css`
					margin-right: 0.6em;
				`}
			>
				{units[make.unitId]?.name}
			</span>
			<span>{price}</span>
		</div>
	);
};

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronIcon
				css={(theme) => css`
					width: ${theme.pxToUnit(8)};
					padding: ${theme.pxToUnit(5)};
					box-sizing: content-box;
				`}
			/>
		</components.DropdownIndicator>
	);
};

const MakeSelect = ({ makes, value, onChange, size = 'm', flex = null, ...props }) => {
	const options = useMemo(() => makes.map((m) => ({ value: m.id, label: m })), [makes]);
	const selection = useMemo(() => {
		const val = makes.find((m) => m.id === value);
		if (val) {
			return { value: val.id, label: val };
		}
		return null;
	}, [makes, value]);

	return (
		<Select
			options={options}
			value={selection}
			isSearchable={false}
			isClearable={false}
			onChange={(v) => onChange(v?.value || null)}
			formatOptionLabel={(data) => <MakeLabel size={size} make={data.label} />}
			components={{
				IndicatorSeparator: null,
				DropdownIndicator,
			}}
			styles={{
				container: (prev) => ({
					...prev,
					flex,
				}),
				control: (prev) => ({
					...prev,
					borderRadius: '99999px',
					borderColor: 'var(--color-fg)',
					minHeight: 'unset',
					'&:hover': {
						borderColor: 'var(--color-fg)',
					},
					'&:focus-within': {
						boxShadow: 'none',
						borderColor: 'var(--color-primary)',
						background: 'rgba(255, 255, 255, 0.8)',
					},
				}),
				dropdownIndicator: (prev) => ({
					...prev,
					color: 'var(--color-fg)',
					'&:hover': {
						color: 'var(--color-fg)',
					},
				}),
				menuPortal: (prev) => ({
					...prev,
					zIndex: 9999,
				}),
				menu: (prev) => ({
					...prev,
					marginTop: '0',
					borderRadius: '0',
					boxShadow: 'none',
					border: '2px solid var(--color-grey2)',
				}),
				menuList: (prev) => ({
					...prev,
					padding: '0',
				}),
				option: (prev, { isFocused, isSelected }) => ({
					...prev,
					color: isSelected ? 'var(--color-bg)' : 'var(--color-fg)',
					background: isSelected ? 'var(--color-fg)' : 'var(--color-bg)',
					border: isFocused
						? '1px solid var(--color-primary)'
						: isSelected
						? '1px solid var(--color-fg)'
						: '1px solid var(--color-bg)',
				}),
				valueContainer: (prev) => ({
					...prev,
					padding: `${11 / 16}em`,
				}),
			}}
			{...props}
		/>
	);
};

export default MakeSelect;
