/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '../../../shop';
import { FieldError, FieldLabel, FormField, Input } from '../../form';
import { FormFieldError, required } from '../../form/final-form';
import { Typography } from '../../typography';

const LoginForm = ({ isOnPage, onSuccess = () => {}, onLostPassword = () => {} }) => {
	const login = useLoginMutation();
	const { t } = useTranslation();

	const onSubmit = async ({ email, password }) => {
		try {
			await login.mutateAsync({ email, password });
		} catch (err) {
			return { [FORM_ERROR]: [t('auth.loginFailed')] };
		}
		onSuccess();
	};
	return (
		<Form onSubmit={onSubmit}>
			{({ handleSubmit, submitting, hasValidationErrors, submitError, hasSubmitErrors }) => (
				<form onSubmit={handleSubmit}>
					<FormField>
						<FieldLabel htmlFor="email">{t('form.fields.email')}</FieldLabel>
						<Field
							id="email"
							component={Input}
							name="email"
							validate={required(t('form.errors.required'))}
							withBorder={isOnPage}
						/>
						<FormFieldError name="email" />
					</FormField>
					<FormField>
						<FieldLabel htmlFor="password">{t('form.fields.password')}</FieldLabel>
						<Field
							id="password"
							component={Input}
							name="password"
							type="password"
							validate={required(t('form.errors.required'))}
							withBorder={isOnPage}
						/>
						<FormFieldError name="password" />
					</FormField>
					{hasSubmitErrors && (
						<div
							css={css`
								text-align: center;
								margin: 0.5em 0;
							`}
						>
							<FieldError size="l" error={submitError} />
						</div>
					)}
					<div
						css={(theme) => css`
							display: flex;
							flex-direction: column-reverse;
							align-items: center;
							margin-top: var(--dimension-spacing-s);
							@media (min-width: ${theme.breakpoint.m}) {
								flex-direction: row;
								justify-content: space-between;
							}
						`}
					>
						<Typography
							as="button"
							type="button"
							onClick={onLostPassword}
							size="l"
							textDecoration="underline"
							css={(theme) => [
								theme.resetButton,
								css`
									margin: 0.5em 0;
								`,
							]}
						>
							{t('auth.lostPassword')}
						</Typography>
						<button
							type="submit"
							disabled={submitting || hasValidationErrors}
							css={(theme) => [
								theme.button({ theme, variant: isOnPage ? 'filled' : 'white', large: true }),
								css`
									font-weight: var(--font-default-weight-regular);
								`,
							]}
						>
							{t('auth.doLogin')}
						</button>
					</div>
				</form>
			)}
		</Form>
	);
};

export default LoginForm;
