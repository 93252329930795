/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { RemoveScroll } from 'react-remove-scroll';
import NavLink from './NavLink';
import useMatchMedia from '../../hooks/matchMedia';
import { useNavigation, useSite } from '../../api/use';
import BackgroundImage from '../image/BackgroundImage';
import LoginButton from '../ecomm/login/LoginButton';
import LanguageNavigation from './LanguageNavigation';

const Navigation = ({ enabled }) => {
	const { hierarchy } = useNavigation();
	const site = useSite();
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width:${theme.breakpoint.mobileNav})`);
	return (
		<RemoveScroll
			as="div"
			aria-hidden={!enabled}
			enabled={enabled}
			css={(theme) => css`
				pointer-events: all;
				position: absolute;
				top: 0;
				left: 0;
				width: ${enabled ? '100vw' : 0};
				height: ${enabled ? '100vh' : 0};
				overflow-x: hidden;
				z-index: var(--zIndex-nav);
				background-color: var(--color-accent);
				color: var(--color-accentContrast);
				visibility: ${enabled ? 'visible' : 'hidden'};
				@media (min-width: ${theme.breakpoint.mobileNav}) {
					display: flex;
				}
			`}
		>
			<div
				css={(theme) => css`
					padding: ${theme.pxToUnit(10)};
					padding-top: ${theme.dimension.header.height.s};

					@media (min-width: ${theme.breakpoint.m}) {
						padding-top: ${theme.dimension.header.height.m};
					}

					@media (min-width: ${theme.breakpoint.mobileNav}) {
						padding: var(--dimension-spacing-s);
						flex: 0 0 50%;
					}
				`}
			>
				<nav>
					<ul
						css={css`
							list-style: none;
							padding: 0;
							margin: 0;
						`}
					>
						{hierarchy &&
							hierarchy.map((page) => (
								<li key={page.id}>
									<NavLink
										page={page}
										size={page.smallNav ? 'navSmall' : 'nav'}
										weight="bold"
										css={css`
											color: #fff;
											&:visited,
											&:active {
												color: #fff;
											}
											&:hover {
												color: var(--color-grey1);
											}
										`}
									/>
								</li>
							))}
					</ul>
				</nav>
				{!isDesktop && (
					<div
						css={(theme) => css`
							display: flex;
							align-items: center;
							margin-top: ${theme.pxToUnit(30)};
							& > button,
							& > a,
							& > * {
								margin-right: ${theme.pxToRem(10)};
							}
						`}
					>
						<LoginButton />
						<LanguageNavigation />
					</div>
				)}
			</div>
			{isDesktop && (
				<div
					css={(theme) => css`
						flex: 0 0 50%;
						background-color: var(--color-accentDark);
						padding: ${theme.pxToUnit(130)};
					`}
				>
					{site.navImage ? <BackgroundImage image={site.navImage} /> : <div />}
				</div>
			)}
		</RemoveScroll>
	);
};

export default Navigation;
