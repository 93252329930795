/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const PrimaryButton = (props) => (
	<button
		css={(theme) => [
			theme.resetButton,
			css`
				background: ${theme.color.bg};
				color: ${theme.color.fg};
				padding: 0.5em 1em;
				&:hover,
				&:focus {
					color: ${theme.color.primaryContrast};
					background: ${theme.color.primary};
				}
			`,
		]}
		{...props}
	/>
);

export const SecondaryButton = (props) => (
	<button
		css={(theme) => [
			theme.resetButton,
			css`
				background: ${theme.color.fg};
				border: 1px solid ${theme.color.bg};
				padding: 0.5em 1em;
				&:hover,
				&:focus {
					background: ${theme.color.bg};
					color: ${theme.color.fg};
				}
			`,
		]}
		{...props}
	/>
);
