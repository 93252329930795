/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Arrow from './Arrow';
import Dots from './Dots';

const ArrowedDots = ({ dots, next, prev, ...props }) => {
	return (
		<div
			css={css`
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex: 1 1 100%;
			`}
			{...props}
		>
			<Arrow dir={-1} onClick={prev} />
			<Dots>{dots}</Dots>
			<Arrow dir={1} onClick={next} />
		</div>
	);
};

export default ArrowedDots;
