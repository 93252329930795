/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react';
import { memo, useMemo, useState } from 'react';

import useMatchMedia from '../../hooks/matchMedia';

import DarkModeContext from './DarkModeContext';

function DarkModeProvider({ children, globalStylesDark }) {
	const match = useMatchMedia('(prefers-color-scheme: dark)');
	const [mode, setMode] = useState(() => {
		if (['dark', 'light'].includes(localStorage.getItem('color-mode'))) {
			return localStorage.getItem('color-mode');
		}
		return match ? 'dark' : 'light';
	});

	const actions = useMemo(
		() => ({
			setDark: () => {
				localStorage.setItem('color-mode', 'dark');
				setMode('dark');
			},
			setLight: () => {
				localStorage.setItem('color-mode', 'light');
				setMode('light');
			},
		}),
		[setMode]
	);

	return (
		<DarkModeContext.Provider
			value={{
				state: { mode, isDark: mode !== 'light' },
				actions,
			}}
		>
			{mode !== 'light' && (
				<Global
					styles={css`
						:root {
							${globalStylesDark}
						}
					`}
				/>
			)}
			{children}
		</DarkModeContext.Provider>
	);
}

export default memo(DarkModeProvider);
