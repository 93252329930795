/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';
import { VisuallyHidden } from 'react-aria';

import useDarkMode from './use';
import { useTranslation } from 'react-i18next';

const DarkModeSwitch = () => {
	const {
		state: { isDark },
		actions: { setDark, setLight },
	} = useDarkMode();
	const { t } = useTranslation();

	return (
		<button
			css={(theme) => [
				theme.button({ theme, variant: 'flat' }),
				css`
					& > svg {
						width: ${theme.pxToEm(32)};
						height: ${theme.pxToEm(32)};
					}
				`,
			]}
			onClick={isDark ? setLight : setDark}
		>
			<VisuallyHidden>{t(`colorMode.${isDark ? 'toLigh' : 'toDark'}`)}</VisuallyHidden>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				enableBackground="new 0 0 24 24"
				viewBox="0 0 24 24"
				fill="currentColor"
				css={css`
					display: block;
				`}
			>
				<rect fill="none" height="24" width="24" />
				{isDark ? (
					<path d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M11,1v4h2V1L11,1z M11,19v4h2v-4L11,19z M23,11l-4,0v2 l4,0V11z M5,11l-4,0l0,2l4,0L5,11z M16.24,17.66l2.47,2.47l1.41-1.41l-2.47-2.47L16.24,17.66z M3.87,5.28l2.47,2.47l1.41-1.41 L5.28,3.87L3.87,5.28z M6.34,16.24l-2.47,2.47l1.41,1.41l2.47-2.47L6.34,16.24z M18.72,3.87l-2.47,2.47l1.41,1.41l2.47-2.47 L18.72,3.87z" />
				) : (
					<path d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z" />
				)}
			</svg>
		</button>
	);
};

export default memo(DarkModeSwitch);
