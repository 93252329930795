import { useState, useEffect } from 'react';

const useScrollThresh = ({ debounce = 0, node = window, thresh = 10 }) => {
	const [isAbove, setAbove] = useState(node.scrollY > thresh);

	useEffect(() => {
		let t = 0;
		const onScroll = () => {
			clearTimeout(t);
			t = setTimeout(() => {
				setAbove(node.scrollY > thresh);
			}, debounce);
		};
		node.addEventListener('scroll', onScroll);

		return () => {
			clearTimeout(t);
			node.removeEventListener('scroll', onScroll);
		};
	}, [debounce, node, setAbove]);

	return isAbove;
};

export default useScrollThresh;
