/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Typography } from '../typography';

const Checkbox = ({ input, meta, children, ...props }) => {
	return (
		<Typography
			size="l"
			as="label"
			css={css`
				display: flex;
				align-items: center;
				position: relative;
				&:focus-within {
					& > div {
						border-width: 2px;
					}
				}
			`}
			{...props}
		>
			<input
				type="checkbox"
				css={css`
					border: 0px none;
					clip: rect(0px, 0px, 0px, 0px);
					height: 1px;
					width: 1px;
					margin: -1px;
					padding: 0px;
					overflow: hidden;
					white-space: nowrap;
					position: absolute;
				`}
				{...input}
			/>
			<div
				css={(theme) => css`
					position: relative;
					width: ${theme.pxToUnit(32)};
					height: ${theme.pxToUnit(32)};
					border: 1px solid var(--color-fg);
					border-radius: 9999px;
					margin-right: 0.6em;
					flex: 0 0 auto;
					&::after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: ${theme.pxToUnit(21)};
						height: ${theme.pxToUnit(21)};
						background: ${input.checked ? 'var(--color-fg)' : 'transparent'};
						transition: background 100ms ease-out;
						border-radius: 9999px;
					}
				`}
			/>
			{children && (
				<span
					css={css`
						margin: 0 0.25em;
					`}
				>
					{children}
				</span>
			)}
		</Typography>
	);
};

export default Checkbox;
