/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { VisuallyHidden } from 'react-aria';
import { FiX as CloseIcon } from 'react-icons/fi';

const OverlayButton = ({ as: Component = 'button', icon = null, label = null, ...props }) => (
	<Component
		css={(theme) => [
			theme.headerButton({ theme }),
			css`
				pointer-events: all;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				width: ${theme.pxToUnit(50)};
				height: ${theme.pxToUnit(50)};
				border-radius: 50%;

				& > svg {
					display: block;
					width: ${theme.pxToUnit(30)};
					height: ${theme.pxToUnit(30)};
				}

				@media (min-width: ${theme.breakpoint.l}) {
					width: ${theme.pxToUnit(80)};
					height: ${theme.pxToUnit(80)};

					& > svg {
						width: ${theme.pxToUnit(40)};
						height: ${theme.pxToUnit(40)};
					}
				}
			`,
		]}
		{...props}
	>
		{label && <VisuallyHidden>{label}</VisuallyHidden>}
		{icon || <CloseIcon />}
	</Component>
);

export default OverlayButton;
