export const composeValidators =
	(...validators) =>
	(value) =>
		validators.reduce((error, validator) => error || validator(value), undefined);

export const validateRecord = (values, fieldValidators) => {
	const errors = {};
	Object.keys(fieldValidators).forEach((key) => {
		errors[key] = fieldValidators[key](values[key]);
	});
	return errors;
};

export const required =
	(message = null) =>
	(value) =>
		value ? undefined : message || 'Required';

export const noValidate = () => undefined;

export const zipCode =
	(message = null) =>
	(rawValue) => {
		const value = typeof rawValue === 'string' ? parseInt(rawValue, 10) : rawValue;
		if (value < 1000 || value > 9999) {
			return message || 'Not a valid ZIP code';
		}
		return undefined;
	};

export const phoneNumber =
	(message = null) =>
	(val) => {
		if (!val) {
			return undefined;
		}
		const value = typeof val === 'number' ? val.toString() : val;

		const phoneNumberError = message || 'Not a valid phone number';
		// Remove all non numeric except '+'
		const digits = value.replace(/[^\d|+]/g, '');
		// If a '+' is included it should be in the beginning
		if (digits.includes('+') && digits[0] !== '+') return phoneNumberError;
		// A number should be at least 10 digits long (e.g. 079 123 45 67)
		// And not longer than 13 (e.g. 0041 79 123 45 67)
		if (digits.length < 10 || digits.length > 13) return phoneNumberError;
		return undefined;
	};

export const email =
	(message = null) =>
	(value) => {
		const re =
			/^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
		return re.test(value) ? undefined : message || 'Not a valid Email address';
	};

export const maxLength =
	(max, message = null) =>
	(value) => {
		if (!value) {
			return undefined;
		}
		return value.length > max ? message || `Max. ${max} characters` : undefined;
	};

export const minLength =
	(min, message = null) =>
	(value) => {
		if (!value) {
			return undefined;
		}
		return value.length < min ? message || `Min. ${min} characters` : undefined;
	};

export const matchString =
	(match, message = null) =>
	(value) => {
		if (!value) {
			return undefined;
		}
		return value !== match ? message || `Does not match ${match}` : undefined;
	};

export const isNumber =
	(message = null) =>
	(rawValue) => {
		if (rawValue === '') {
			return undefined;
		}
		const value = typeof rawValue === 'string' ? parseInt(rawValue, 10) : rawValue;
		return isNaN(value) ? message || 'Not a valid number' : undefined;
	};

export const isFileTypes = (types, message) => (value) => {
	if (!value || !value.type) return undefined;
	if (types.includes(value.type)) return undefined;
	return message || 'File type is not accepted';
};
