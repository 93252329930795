import create from 'zustand';

const useViewState = create((set) => ({
	navOpen: false,
	authOverlay: false,
	productOverlay: false,
	setNavOpen: (value) => set((state) => ({ ...state, navOpen: value, authOverlay: false })),
	setAuthOverlay: (value) => set((state) => ({ ...state, navOpen: false, authOverlay: value, productOverlay: false })),
	setProductOverlay: (value) => {
		set((state) => ({ ...state, navOpen: false, authOverlay: false, productOverlay: value }));
	},
	closeAll: () =>
		set((state) => ({
			...state,
			navOpen: false,
			authOverlay: false,
			productOverlay: state.productOverlay.placeholder ? state.productOverlay : false,
		})),
}));

export default useViewState;
