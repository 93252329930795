/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Typography } from '../typography';

const FieldError = ({ error, ...props }) =>
	error ? (
		<Typography
			as="span"
			size="m"
			css={(theme) => css`
				color: var(--color-error);
				margin-top: 0.25em;
				padding: 0 ${theme.pxToUnit(10)};
				grid-area: error;
			`}
			{...props}
		>
			{error}
		</Typography>
	) : null;

export default FieldError;
