/** @jsxImportSource @emotion/react */
import { ThemeProvider, Global } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import { Suspense, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { OverlayProvider } from 'react-aria';

import ApiProvider from './api/provider';

import { QueryProvider } from './shop';

import ErrorBoundary from './ErrorBoundary';
import PageWrap from './views/PageWrap';

import { theme, globalStyles, globalStylesDark } from './theme';

import DarkModeProvider from './components/dark-mode/DarkModeProvider';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import LoadingScreen from './components/LoadingScreen';
import useViewState from './hooks/useViewState';
import ShopOverlays from './components/ecomm/ShopOverlays';
import { ConsentPopup } from './consent';
import { useAnalyticsConsent } from './hooks/useAnalyticsConsent';

const AppCore = () => {
	const closeAll = useViewState(useCallback((state) => state.closeAll, []));
	const setAuthOverlay = useViewState(useCallback((state) => state.setAuthOverlay, []));
	const location = useLocation();
	useAnalyticsConsent('analytics_storage', window.gtag);

	useEffect(() => {
		closeAll();
		setAuthOverlay(false);
	}, [location, closeAll, setAuthOverlay]);

	return (
		<Suspense fallback={<LoadingScreen />}>
			<ShopOverlays />
			<Header />
			<PageWrap />
			<Footer />
		</Suspense>
	);
};

const App = () => {
	return (
		<HelmetProvider>
			<ThemeProvider theme={theme}>
				<Global styles={globalStyles} />
				<DarkModeProvider globalStylesDark={globalStylesDark}>
					<ErrorBoundary>
						<ApiProvider>
							<QueryProvider>
								<OverlayProvider>
									<AppCore />
									<ConsentPopup />
								</OverlayProvider>
							</QueryProvider>
						</ApiProvider>
					</ErrorBoundary>
				</DarkModeProvider>
			</ThemeProvider>
		</HelmetProvider>
	);
};

export default App;
