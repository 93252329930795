/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo } from 'react';
import { FaFacebookSquare as IconFb, FaInstagramSquare as IconInsta } from 'react-icons/fa';

import { useSite } from '../../api/use';

import Container from './Container';
import FlexContainer from './FlexContainer';
import FlexCol from './FlexCol';
import Image from '../image/Image';
import RichText from '../layout/RichText';
import DarkModeSwitch from '../dark-mode/DarkModeSwitch';

const SmIcon = ({ link, icon }) =>
	!!link && (
		<a
			size="xl"
			href={link}
			target="_blank"
			rel="noopener noreferrer nofollow"
			css={(theme) => css`
				& svg {
					width: ${theme.pxToEm(32)};
					height: ${theme.pxToEm(32)};
				}
			`}
		>
			{icon}
		</a>
	);

const colStyle = (theme) => css`
	margin-top: 1em;
	margin-bottom: 1em;

	@media (min-width: ${theme.breakpoint.m}) {
		margin-top: 0;
		margin-bottom: 0;
	}
`;

const colStyle2 = (theme) => css`
	margin-top: 1em;
	margin-bottom: 1em;

	@media (min-width: ${theme.breakpoint.m}) {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	@media (min-width: ${theme.breakpoint.l}) {
		margin-top: 0;
		margin-bottom: 0;
	}
`;

const Footer = () => {
	const { footer } = useSite();
	return (
		<div
			css={(theme) => css`
				--color-bg: var(--color-footerBg);
				--color-fg: var(--color-footerFg);

				background: var(--color-bg);
				color: var(--color-fg);
				margin-top: ${theme.pxToUnit(60)};
				@media (min-width: ${theme.breakpoint.m}) {
					margin-top: ${theme.pxToUnit(80)};
				}
				@media (min-width: ${theme.breakpoint.l}) {
					margin-top: ${theme.pxToUnit(100)};
				}
			`}
		>
			<Container>
				<FlexContainer
					css={(theme) => css`
						padding: 2em 0;

						@media (min-width: ${theme.breakpoint.m}) {
							padding: 4em 0;
						}
					`}
				>
					<FlexCol s="100%" m="50%" l="33.333%">
						<RichText content={footer?.address} />
					</FlexCol>

					<FlexCol s="100%" m="50%" l="33.333%" css={colStyle}>
						<div
							css={(theme) => css`
								@media (min-width: ${theme.breakpoint.m}) {
									padding-right: ${theme.pxToUnit(10)};
								}
							`}
						>
							<RichText content={footer?.contact} />
						</div>
					</FlexCol>

					<FlexCol s="100%" m="50%" l="33.333%" css={colStyle2}>
						{!!footer?.certifications && <Image {...footer.certifications} noLightbox />}
					</FlexCol>

					<FlexCol s="0%" m="0%" l="33.333%"></FlexCol>

					<FlexCol s="100%" m="50%" l="33.333%">
						<div
							css={(theme) => css`
								@media (min-width: ${theme.breakpoint.m}) {
									padding-right: ${theme.pxToUnit(10)};
								}
							`}
						>
							<RichText content={footer?.resellers} />
						</div>
					</FlexCol>

					<FlexCol
						s="100%"
						m="50%"
						l="33.333%"
						css={(theme) => css`
							${colStyle(theme)}
						`}
					>
						<RichText content={footer?.newsletter} />
					</FlexCol>

					<FlexCol
						s="100%"
						m="100%"
						l="33.333%"
						css={css`
							align-self: flex-end;
						`}
					>
						<RichText content={footer?.nav} />
					</FlexCol>

					<FlexCol s="0%" m="0%" l="33.333%"></FlexCol>

					<FlexCol
						s="100%"
						m="50%"
						l="33.333%"
						css={(theme) => css`
							align-self: flex-end;
							text-align: right;
							display: flex;
							align-items: center;
							justify-content: end;
							gap: 0.6em;

							& a,
							& svg {
								display: block;
							}

							${colStyle(theme)}
						`}
					>
						{!!footer?.fbLink && <SmIcon link={footer.fbLink} icon={<IconFb />} />}
						{!!footer?.instaLink && <SmIcon link={footer.instaLink} icon={<IconInsta />} />}
						<DarkModeSwitch />
					</FlexCol>
				</FlexContainer>
			</Container>
		</div>
	);
};

export default memo(Footer);
