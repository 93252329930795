/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useMemo } from 'react';
import { motion } from 'framer-motion';

import { useImageLoader } from './index.js';
import { Typography } from '../typography/index.js';

const BackgroundImage = ({
	image,
	backgroundColor,
	backgroundBlendMode,
	backgroundSize = 'cover',
	showCopyright = true,
	children,
	...props
}) => {
	const { ref, src } = useImageLoader({ aspect: image ? image.aspect : 1, id: image?.id });

	const bgPos = useMemo(
		() => !!image && (!image.focus ? '50% 50%' : `${(image.focus.x + 1) * 50}% ${(image.focus.y + 1) * 50}%`),
		[image]
	);

	return (
		<div
			ref={ref}
			css={css`
				position: relative;
				z-index: 0;
				height: 100%;
				width: 100%;
			`}
			{...props}
		>
			<div
				css={[
					css`
						position: absolute;
						z-index: -1;
						width: 100%;
						height: 100%;
						background: #ccc url(${image?.thumb});
						background-size: ${backgroundSize};
						background-repeat: no-repeat;
						background-position: ${bgPos};
					`,
					backgroundColor
						? css`
								background-color: ${backgroundColor};
						  `
						: null,
					backgroundBlendMode
						? css`
								background-blend-mode: ${backgroundBlendMode};
						  `
						: null,
				]}
			/>
			{!!src && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					css={[
						css`
							position: absolute;
							z-index: -1;
							width: 100%;
							height: 100%;
							background-size: ${backgroundSize};
							background-repeat: no-repeat;
							background-position: ${bgPos};
							background-image: url(${src});
						`,
						backgroundColor
							? css`
									background-color: ${backgroundColor};
							  `
							: null,
						backgroundBlendMode
							? css`
									background-blend-mode: ${backgroundBlendMode};
							  `
							: null,
					]}
				/>
			)}
			{showCopyright && !!image?.copyright && (
				<Typography
					size="s"
					css={css`
						position: absolute;
						right: 0.6em;
						bottom: 0.4em;
						color: white;
						text-shadow: 0 0 1em black;
					`}
				>
					© {image.copyright}
				</Typography>
			)}
			{children}
		</div>
	);
};

export default memo(BackgroundImage);
