import { useEffect, useState, useCallback, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import useDelayedInView from '../../hooks/useDelayedInView';

const dpr = window.devicePixelRatio || 1;

/*
 * webp feature detect
 */
const support_format_webp = () => {
	const elem = document.createElement('canvas');
	if (!!elem.getContext && !!elem.getContext('2d')) {
		return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
	} else {
		return false;
	}
};
const canWebP = support_format_webp();

/*
 * creates an image
 */
export const createImage = (width, height, bgColor) => {
	// we create a canvas element
	const canvas = document.createElement('canvas');
	canvas.height = height;
	canvas.width = width;
	const context = canvas.getContext('2d');
	context.fillStyle = bgColor || '#efefef';
	context.fillRect(0, 0, width, height);
	return canvas.toDataURL('image/png');
};

/*
 * waits for an image to be loaded
 */
export const loadImage = (src) =>
	new Promise((resolve, reject) => {
		if (typeof src === 'undefined' || !src) {
			reject('src is invalid: ' + src);
			return;
		}
		const img = new Image();

		img.onload = () => {
			img.removeEventListener('load', img.onload);
			img.removeEventListener('error', img.onerror);
			resolve(img);
		};

		img.onerror = (err) => {
			img.removeEventListener('load', img.onload);
			img.removeEventListener('error', img.onerror);
			reject(err);
		};

		img.src = src;

		if (img.complete) {
			resolve(img);
		}
	});

const inViewConfBase = {
	threshold: [0, 1],
	triggerOnce: false,
	rootMargin: '50% 0px',
	skip: false,
};

export const useImageLoader = ({ aspect, id }) => {
	const ref = useRef();
	// we store the intersection observer conf to skip / unskip it
	const [inViewConf, setInViewConf] = useState(inViewConfBase);
	// we set src after image was loaded
	const [src, setSrc] = useState(null);

	const { ref: inViewRef, inView, entry } = useDelayedInView({ delay: 200, conf: inViewConf });

	const setRefs = useCallback(
		(node) => {
			ref.current = node;
			inViewRef(node);
		},
		[ref, inViewRef]
	);

	const handleSizeChange = useCallback(() => {
		// let's reactivate inView on resize
		setInViewConf({
			...inViewConfBase,
			skip: false,
		});
	}, [setInViewConf]);

	useResizeDetector({ targetRef: ref, onResize: handleSizeChange, refreshMode: 'debounce', refreshRate: 300 });

	// let's deactivate inView when appeared
	useEffect(() => {
		if (!inView) {
			return;
		}
		setInViewConf({
			...inViewConfBase,
			skip: true,
		});
	}, [inView, setInViewConf]);

	// let's load the image
	useEffect(() => {
		if (!entry || !inView) return;
		let alive = true;
		const src = `/api/img/${id}/width/${Math.ceil((dpr * entry.boundingClientRect.width) / 200) * 200}${
			canWebP ? '.webp' : ''
		}${document.location.search.indexOf('stage=Stage') !== -1 ? '?stage=Stage' : ''}`;

		loadImage(src)
			.then((img) => {
				if (!alive) return;
				setSrc(img.src);
			})
			.catch(() => {});
		return () => {
			alive = false;
		};
	}, [id, entry, inView, setSrc]);

	return { ref: setRefs, src, rect: entry?.boundingClientRect };
};
