/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const FormField = ({ as: Component = 'div', ...props }) => {
	return (
		<Component
			css={(theme) => [
				css`
					display: flex;
					flex-direction: column;
					margin-bottom: 1em;
					@media (min-width: ${theme.breakpoint.m}) {
						display: grid;
						grid-template-columns: ${theme.pxToUnit(300)} auto;
						grid-template-areas:
							'label input'
							'. error'
							'. description';
						align-items: center;
					}
				`,
				Component === 'fieldset' &&
					css`
						border: 0;
						padding: 0;
					`,
			]}
			{...props}
		/>
	);
};

export default FormField;
