/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Typography } from '../typography';

const FieldLabel = ({ as: Component = 'label', ...props }) => {
	return (
		<Typography
			size="l"
			as={Component}
			css={(theme) => css`
				margin-bottom: 0.25em;
				@media (min-width: ${theme.breakpoint.m}) {
					margin-bottom: 0;
					grid-area: label;
				}
			`}
			{...props}
		/>
	);
};

export default FieldLabel;
