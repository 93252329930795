/** @jsxImportSource @emotion/react */

export const ArrowRightIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.615 6.615" fill="currentcolor" {...props}>
		<path
			transform="scale(.26458)"
			d="m12.11.049-1.415 1.414L21.12 11.5H0v2h21.227l-9.692 10.063 1.416 1.416 10.662-11.077L24 13.5l1-1.04-1.44-1.386L12.11.05z"
		/>
	</svg>
);

export const CartIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="1399.65 59.45 26.7 23.567" fill="currentcolor" {...props}>
		<g>
			<path
				d="M1423.635 63.127h-17.405l-.478-2.727a1.187 1.187 0 0 0-.305-.617.97.97 0 0 0-.702-.31h-4.05a1.032 1.032 0 0 0-.943.695 1.262 1.262 0 0 0-.083.453c0 .633.46 1.148 1.026 1.148h3.206l2.62 14.994a.358.358 0 0 0 .024.074.492.492 0 0 1 .02.05 1.076 1.076 0 0 0 .056.166 1.112 1.112 0 0 0 .117.197 1.552 1.552 0 0 0 .01.012 1.622 1.622 0 0 1 .001.003l.024.033a.771.771 0 0 0 .068.086 1.006 1.006 0 0 0 .203.152.771.771 0 0 1 .036.024.378.378 0 0 0 .074.045.902.902 0 0 0 .373.085h14.593a2.648 2.648 0 0 0 2.255-1.375 3.234 3.234 0 0 0 .38-1.016l1.51-8.516c.042-.216.063-.434.063-.65a3.219 3.219 0 0 0-.596-1.882 2.72 2.72 0 0 0-1.138-.928 2.443 2.443 0 0 0-.959-.196Zm-1.515 12.267h-13.747l-1.742-9.97h17.005a.596.596 0 0 1 .151.02c.177.046.29.167.347.246a.728.728 0 0 1 .021.032c.07.11.156.31.101.592l-1.51 8.517a.729.729 0 0 1-.219.4.603.603 0 0 1-.407.163Zm-15.575 1.443.001.003"
				fillRule="evenodd"
			/>
		</g>
		<g>
			<path
				d="M1409.77 78.51a2.246 2.246 0 0 0-2.244 2.245 2.246 2.246 0 0 0 2.243 2.244 2.247 2.247 0 0 0 2.245-2.244 2.247 2.247 0 0 0-2.245-2.245Zm-.74 2.245a.74.74 0 0 0 .74.738.74.74 0 0 0 .74-.738.74.74 0 0 0-.74-.74.74.74 0 0 0-.74.74Z"
				fillRule="evenodd"
			/>
		</g>
		<g>
			<path
				d="M1419.551 78.51a2.247 2.247 0 0 0-2.245 2.245 2.247 2.247 0 0 0 2.245 2.244 2.247 2.247 0 0 0 2.244-2.244 2.247 2.247 0 0 0-2.244-2.245Zm0 2.983a.74.74 0 1 1 0-1.48.74.74 0 0 1 0 1.48Z"
				fillRule="evenodd"
			/>
		</g>
	</svg>
);

export const ChevronIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="567.483 380.233 7.367 5.9" fill="currentcolor" {...props}>
		<path d="m571.168 386.129-3.668-5.868h7.335z" fillRule="evenodd" />
	</svg>
);

export const SearchIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="513.017 339.783 30.517 30.55" fill="currentcolor" {...props}>
		<g>
			<path d="M525.383 364c6.526 0 11.844-5.318 11.844-11.844s-5.318-11.845-11.844-11.845-11.844 5.32-11.844 11.845c0 6.526 5.318 11.844 11.844 11.844zm0-2.6a9.225 9.225 0 0 1-9.244-9.244 9.225 9.225 0 0 1 9.244-9.244 9.225 9.225 0 0 1 9.244 9.244 9.225 9.225 0 0 1-9.244 9.244z" />
			<path d="M534.879 360.14a1.5 1.5 0 0 0-1.053 2.573l6.5 6.539a1.5 1.5 0 1 0 2.127-2.115l-6.5-6.54a1.5 1.5 0 0 0-1.074-.456z" />
			<path d="M525.37 344.213a1 1 0 1 0-.007 2 5.987 5.987 0 0 1 4.951 2.642 1 1 0 0 0 .004.008c.363.529.633 1.115.795 1.735a1 1 0 0 0 0 .004 6.07 6.07 0 0 1 .203 1.56 1 1 0 1 0 2 .002c0-.699-.09-1.396-.27-2.072a7.553 7.553 0 0 0-1.073-2.353l-.002-.002a7.992 7.992 0 0 0-6.602-3.524z" />
		</g>
	</svg>
);

export const IconPlus = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="174.8 1742.333 20.567 19.883" {...props}>
		<g strokeLinejoin="round" strokeLinecap="round" stroke="currentcolor">
			<path d="M175.531 1752.274h19.12" />
			<path d="M185.092 1743.06v18.429" />
		</g>
	</svg>
);
export const IconMinus = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="1518.267 1464.767 20.583 1.45" {...props}>
		<path d="M1519.5 1465.998h19.12" strokeLinejoin="round" strokeLinecap="round" stroke="currentcolor" />
	</svg>
);

export const TilesIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.615 6.615" height="25" width="25" {...props}>
		<path
			d="M1.455 290.385c-.801 0-1.455.654-1.455 1.455 0 .8.654 1.455 1.455 1.455s1.455-.654 1.455-1.455-.654-1.455-1.455-1.455zm0 .449c.56 0 1.008.447 1.008 1.006 0 .559-.449 1.008-1.008 1.008-.559 0-1.008-.45-1.008-1.008 0-.56.449-1.006 1.008-1.006zM5.16 294.09c-.8 0-1.455.654-1.455 1.455S4.36 297 5.16 297s1.455-.654 1.455-1.455-.654-1.455-1.455-1.455zm0 .447c.56 0 1.006.449 1.006 1.008 0 .559-.447 1.008-1.006 1.008-.559 0-1.008-.449-1.008-1.008 0-.56.45-1.008 1.008-1.008zM1.455 294.09c-.801 0-1.455.654-1.455 1.455S.654 297 1.455 297s1.455-.654 1.455-1.455-.654-1.455-1.455-1.455zm0 .447c.56 0 1.008.449 1.008 1.008 0 .559-.449 1.008-1.008 1.008-.559 0-1.008-.449-1.008-1.008 0-.56.449-1.008 1.008-1.008zM5.16 290.385c-.8 0-1.455.654-1.455 1.455 0 .8.654 1.455 1.455 1.455s1.455-.654 1.455-1.455-.654-1.455-1.455-1.455zm0 .449c.56 0 1.006.447 1.006 1.006 0 .559-.447 1.008-1.006 1.008-.559 0-1.008-.45-1.008-1.008 0-.56.45-1.006 1.008-1.006z"
			transform="translate(0 -290.385)"
		/>
	</svg>
);

export const ListIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.615 6.615" height="25" width="25" {...props}>
		<path
			d="M.793 290.385a.799.799 0 0 0-.793.795c0 .435.358.793.793.793a.799.799 0 0 0 .795-.793.8.8 0 0 0-.795-.795zm0 .58a.21.21 0 0 1 .215.215.21.21 0 0 1-.215.215.21.21 0 0 1-.213-.215.21.21 0 0 1 .213-.215z"
			transform="translate(0 -290.385)"
		/>
		<path
			d="M2.666 290.65a.534.534 0 0 0-.527.53c0 .287.24.529.527.529h3.441a.534.534 0 0 0 .528-.53c0-.287-.24-.529-.528-.529z"
			transform="translate(0 -290.385)"
		/>
		<path
			d="M.793 295.412a.799.799 0 0 0-.793.795c0 .435.358.793.793.793a.799.799 0 0 0 .795-.793.8.8 0 0 0-.795-.795zm0 .58a.21.21 0 0 1 .215.215.21.21 0 0 1-.215.213.208.208 0 0 1-.213-.213.21.21 0 0 1 .213-.215z"
			transform="translate(0 -290.385)"
		/>
		<path
			d="M2.666 295.678a.534.534 0 0 0-.527.529c0 .287.24.53.527.53h3.441a.534.534 0 0 0 .528-.53c0-.287-.24-.53-.528-.53z"
			transform="translate(0 -290.385)"
		/>
		<path
			d="M.793 292.898a.799.799 0 0 0-.793.795c0 .435.358.793.793.793a.799.799 0 0 0 .795-.793.8.8 0 0 0-.795-.795zm0 .58a.21.21 0 0 1 .215.215.21.21 0 0 1-.215.215.21.21 0 0 1-.213-.215.21.21 0 0 1 .213-.214z"
			transform="translate(0 -290.385)"
		/>
		<path
			d="M2.666 293.164a.534.534 0 0 0-.527.53c0 .287.24.529.527.529h3.441a.534.534 0 0 0 .528-.53c0-.287-.24-.529-.528-.529z"
			transform="translate(0 -290.385)"
		/>
	</svg>
);

export const UserIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="-20965.316 -7147.267 25.809 27.429" {...props}>
		<path d="M-20952.188-7147.264c-3.314-.124-6.098 2.535-6.124 5.852a.904.904 0 0 0 0 .01v.084a.904.904 0 0 0 0 .01c.02 2.164 1.262 3.757 2.833 4.76-5.51 1.245-9.728 5.745-9.837 11.408a.904.904 0 0 0 0 .017c0 .158 0 .316.01.475.06 1.339.395 2.413 1.043 3.2.649.789 1.566 1.218 2.543 1.42 1.955.406 4.299.074 6.914-.077a41.44 41.44 0 0 1 2.391-.076c2.89 0 5.581.425 7.832.326 1.125-.05 2.169-.228 3.059-.766.889-.538 1.546-1.465 1.826-2.666a.904.904 0 0 0 0-.014c.13-.6.193-1.213.185-1.826 0-2.887-1.121-5.668-3.125-7.805-1.715-1.832-3.947-3.069-6.371-3.69.236-.17.491-.29.709-.503 1.836-1.784 2.185-4.305 1.399-6.352-.787-2.046-2.733-3.687-5.292-3.783zm-.07 1.807c1.837.069 3.118 1.188 3.67 2.625s.348 3.125-.971 4.406c-1.318 1.281-3.011 1.435-4.431.842-1.421-.593-2.502-1.906-2.518-3.744v-.068a4.084 4.084 0 0 1 4.25-4.06zm-.158 10.168c3.23-.015 6.308 1.297 8.455 3.59 1.699 1.812 2.633 4.15 2.637 6.574a.904.904 0 0 0 0 .012c0 .475-.04.949-.141 1.414-.195.836-.512 1.23-1 1.525-.489.296-1.23.466-2.201.508-1.943.085-4.695-.326-7.754-.326-.833 0-1.667.027-2.498.078-2.685.155-5.004.41-6.44.112-.718-.15-1.18-.394-1.513-.8-.334-.405-.584-1.044-.633-2.132 0-.125-.01-.25-.01-.375.107-5.485 4.658-9.954 10.434-10.16a.904.904 0 0 0 .01 0c.216-.011.433-.018.649-.018z" />
	</svg>
);

export const XIcon = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="1518.267 1472.967 26.562 26.562" {...props}>
		<path
			fill="currentcolor"
			transform="translate(1518.267 1472.967)"
			d="M.5 0a.5.5 0 0 0-.35.86L12.572 13.28.15 25.703a.5.5 0 1 0 .707.707l12.424-12.422L25.703 26.41a.5.5 0 1 0 .707-.707L13.988 13.281 26.41.86A.5.5 0 0 0 26.047 0a.5.5 0 0 0-.344.152L13.281 12.574.857.152A.5.5 0 0 0 .5 0z"
		/>
	</svg>
);

export const GotoIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Layer_1"
		x="0"
		y="0"
		version="1.1"
		viewBox="0 0 12.168 12.171"
		{...props}
	>
		<path fill="none" stroke="currentcolor" strokeWidth="2" d="M.707 11.464 11.171 1M1.867 1h9.301v9.117" />
	</svg>
);
