import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './translation/i18n';

function browserSupportsAllFeatures() {
	return (
		window.Promise && window.fetch && window.Symbol && window.Intl && window.matchMedia && window.IntersectionObserver
	);
}

function loadScript(src, done) {
	var js = document.createElement('script');
	js.src = src;
	js.onload = function () {
		done();
	};
	js.onerror = function () {
		done(new Error('Failed to load script ' + src));
	};
	document.head.appendChild(js);
}

const history = createBrowserHistory();
wrapHistory(history);

function main() {
	ReactDOM.render(
		<React.StrictMode>
			<HistoryRouter history={history}>
				<App />
			</HistoryRouter>
		</React.StrictMode>,
		document.getElementById('root')
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
}

browserSupportsAllFeatures()
	? main()
	: loadScript(
			'https://polyfill.io/v3/polyfill.min.js?features=matchMedia%2CIntl%2CSSymbol%2CFetch%2CPromise%2CElement.prototype.scroll%2CElement.prototype.scrollIntoView%2CscrollIntoView%2Csmoothscroll%2IntersectionObserver%2CIntersectionObserverEntry%2CustomEvent',
			main
	  );
