import { useCallback, useMemo } from 'react';
import create from 'zustand';
import { useGetCart, useSetItemMutation } from '../api';

// Won't be added to the document, but serves as an EventTarget
export const cartEvents = document.createElement('img');
let eventKey = 0;

export const triggerCartEvent = (type, payload) => {
	eventKey += 1;
	cartEvents.dispatchEvent(new CustomEvent(type, { detail: { payload, type, key: eventKey } }));
};

export const useCartEvents = create((set) => ({
	events: [],
	counter: 0,
	addEvent: (type, payload) => {
		set((state) => ({
			events: [...state.events, { key: state.counter + 1, type, payload }],
			counter: state.counter + 1,
		}));
	},
	popEvent: (key) => set((state) => ({ ...state, events: state.events.filter((e) => e.key !== key) })),
}));

export const useCart = () => {
	const { data, isLoading } = useGetCart();
	return useMemo(
		() => ({
			...data.cart,
			isLoading,
			isInitalData: !!data.isInitalData,
			itemCount: data.cart.items.reduce((prev, curr) => prev + curr.quantity, 0),
		}),
		[data, isLoading]
	);
};

export const useAddToCart = () => {
	const cart = useCart();
	const mutation = useSetItemMutation();
	const mutate = useCallback(
		async (makeId, count, title) => {
			const existing = cart.items.find((item) => item.make.id === makeId);
			const quantity = (existing ? existing.quantity : 0) + count;
			const result = await mutation.mutateAsync({ makeId, quantity });
			triggerCartEvent(quantity > 0 ? 'added' : 'removed', { makeId, quantity, title });
			return result;
		},
		[cart, mutation]
	);

	return mutate;
};
