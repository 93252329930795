/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const Textarea = ({ input, meta, size = 'l', weight = 'regular', withBorder = false, ...props }) => {
	return (
		<textarea
			css={(theme) => [
				css`
					grid-area: input;
					appearance: none;
					background: white;
					padding: ${theme.pxToUnit(10)};
					box-sizing: border-box;
					border: ${withBorder ? '1px solid var(--color-fg)' : 'none'};
					border-radius: ${theme.pxToRem(25)};
					${theme.typography.getStyle({ theme, size, weight })}
					&:focus {
						background: rgba(255, 255, 255, 0.8);
					}
					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
					&[type='number'] {
						-moz-appearance: textfield;
					}
				`,
				meta &&
					meta.touched &&
					meta.error &&
					css`
						border-color: var(--color-error);
					`,
			]}
			{...input}
			{...props}
		/>
	);
};

export default Textarea;
