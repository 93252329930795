import { memo, useReducer, useMemo, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { fetchNavigation, fetchPage, fetchSite, fetchInitial } from './services';
import ApiContext from './context';

const initialState = {
	page: { vitality: { loaded: false, error: null, loading: null } },
	navigation: { loaded: false },
	site: { loaded: false },
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_INITIAL':
			return {
				...state,
				...action.payload,
			};
		case 'SET_PAGE_VITALITY':
			return {
				...state,
				page: {
					...state.page,
					vitality: { loaded: false, error: null, loading: null, ...action.payload },
				},
			};
		case 'SET_PAGE':
			return {
				...state,
				page: {
					...action.payload,
					vitality: { loaded: false, error: null, loading: null, ...action.payload.vitality },
				},
			};
		case 'SET_NAVIGATION':
			return { ...state, navigation: action.payload };
		case 'SET_SITE':
			return { ...state, site: action.payload };
		default:
			return state;
	}
};

const hierarchizeNavigation = (data, parentId = 0) => {
	return !data.length
		? null
		: data
				.map((p) => {
					if (p.parentId === parentId) {
						return {
							...p,
							children: hierarchizeNavigation(
								data.filter((_p) => _p.parentId === p.id),
								p.id
							),
						};
					}
					return null;
				})
				.filter((p) => !!p);
};

function ApiProvider({ children }) {
	const navigate = useNavigate();
	const { search } = useLocation();
	const [state, dispatch] = useReducer(reducer, initialState);
	const knownPages = useRef({});
	const initialized = useRef(false);
	const actions = useMemo(() => {
		const getPage = async (href) => {
			if (knownPages.current[href]) {
				return {
					type: 'SET_PAGE',
					payload: {
						$url: href,
						...knownPages.current[href],
						vitality: { loaded: true },
					},
				};
			}
			dispatch({
				type: 'SET_PAGE_VITALITY',
				payload: { loading: true },
			});
			try {
				const data = await fetchPage(href);
				knownPages.current[href] = data;
				return {
					type: 'SET_PAGE',
					payload: { $url: href, vitality: { loaded: true }, ...data },
				};
			} catch (err) {
				return {
					type: 'SET_PAGE',
					payload: { $url: href, vitality: { error: true } },
				};
			}
		};

		return {
			getInitial: async (url, search = '') => {
				const fullUrl = url + search;

				dispatch({
					type: 'SET_INITIAL',
					payload: {
						page: { $url: fullUrl, vitality: { loading: true } },
						navigation: { loading: true },
						site: { loading: true },
					},
				});
				try {
					const { page, site, navigation } = await fetchInitial(fullUrl);
					const payload = {};
					if (page) {
						knownPages.current[fullUrl] = page;
						payload.page = { $url: fullUrl, vitality: { loaded: true }, ...page };
					}
					if (navigation) {
						payload.navigation = {
							loaded: true,
							locale: navigation.locale,
							pages: navigation.pages,
							hierarchy: hierarchizeNavigation(navigation.pages),
						};
					}
					if (site) {
						payload.site = { loaded: true, ...site };
					}
					dispatch({
						type: 'SET_INITIAL',
						payload,
					});
				} catch (err) {
					dispatch({
						type: 'SET_INITIAL',
						payload: {
							page: {
								$url: fullUrl,
								vitality: {
									error: true,
								},
							},
							navigation: { error: true },
							site: { error: true },
						},
					});
				}
			},

			getPage: async (path, search = '') => {
				const href = path + search;
				const payload = await getPage(href);
				dispatch(payload);
			},

			getNavigation: async (locale = '', search = '') => {
				dispatch({
					type: 'SET_NAVIGATION',
					payload: { loading: true },
				});
				try {
					const data = await fetchNavigation(locale + search);
					dispatch({
						type: 'SET_NAVIGATION',
						payload: {
							loaded: true,
							locale: data.locale,
							pages: data.pages,
							hierarchy: hierarchizeNavigation(data.pages),
						},
					});
				} catch (err) {
					dispatch({
						type: 'SET_NAVIGATION',
						payload: { error: true },
					});
				}
			},

			getSite: async (locale = '', search = '') => {
				dispatch({
					type: 'SET_SITE',
					payload: { loading: true },
				});
				try {
					const data = await fetchSite(locale + search);
					dispatch({
						type: 'SET_SITE',
						payload: { loaded: true, ...data },
					});
				} catch (err) {
					dispatch({
						type: 'SET_SITE',
						payload: { error: true },
					});
				}
			},

			/*
			 * we have to load page data first before changing history for scroll restore
			 */
			navigate: async (href, config = {}) => {
				// We want to navigate back or forwards. Since we already have these pages, we navigate instantly.
				// This avoids unnecessary state changes.
				if (config.jumpHistory && typeof config.jumpHistory === 'number') {
					navigate(config.jumpHistory, config.options || {});
					return;
				}
				const payload = await getPage(href);
				navigate(href, config.options || {});
				if (config.onNavigate) {
					config.onNavigate();
				}
				dispatch(payload);
			},
		};
	}, [navigate, dispatch, knownPages]);

	/*
	 *
	 */
	useEffect(() => {
		initialized.current = true;
	}, []);

	/*
	 * let's reload our site data
	 * if the page's locale or location search changed,
	 */
	useEffect(() => {
		if (!state.page.locale || state.site.loading || state.site.locale === state.page.locale) return;
		actions.getSite(state.page.locale, search);
	}, [state.page.locale, state.site.locale, state.site.loading, actions, search]);

	/*
	 * let's reload our navigation data
	 * if the page's locale or location search changed,
	 */
	useEffect(() => {
		if (!state.page.locale || state.navigation.loading || state.navigation.locale === state.page.locale) return;
		actions.getNavigation(state.page.locale, search);
	}, [state.page.locale, state.navigation.locale, state.navigation.loading, actions, search]);

	return (
		<ApiContext.Provider
			value={{
				state,
				actions,
				initialized,
			}}
		>
			{children}
		</ApiContext.Provider>
	);
}

export default memo(ApiProvider);
