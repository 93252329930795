/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useEffect, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { useTranslation } from 'react-i18next';
import Input from '../form/Input';
import { IconMinus, IconPlus } from '../icons';
import useDebounce from '../../hooks/useDebounce';

const IconButton = ({ children, ...props }) => (
	<button
		css={(theme) => [
			theme.resetButton,
			css`
				display: inline-flex;
				align-items: center;
				cursor: pointer;
				padding: ${theme.pxToUnit(5)};

				&:not(:disabled) {
					&:hover,
					&:focus,
					&:active {
						color: ${theme.color.primary};
					}
				}

				& > svg {
					width: ${theme.pxToUnit(20)};
				}
			`,
		]}
		{...props}
	>
		{children}
	</button>
);

const ItemCountInput = memo(({ value = 0, onChange, min = 0, max, compact }) => {
	const { t } = useTranslation();
	const [newValue, setNewValue] = useState(value);
	const debouncedValue = useDebounce(newValue, 400);

	useEffect(() => {
		onChange(debouncedValue);
	}, [debouncedValue, onChange]);

	return (
		<div
			css={css`
				display: flex;
			`}
		>
			<Input
				aria-label={t('shop.itemCount')}
				value={newValue}
				type="number"
				min={min}
				max={max}
				weight="bold"
				size="m"
				withBorder
				onChange={(e) => setNewValue(parseInt(e.target.value))}
				css={(theme) => [
					css`
						text-align: center;

						max-width: ${theme.pxToUnit(64)};

						@media (min-width: ${theme.breakpoint.s}) {
							max-width: ${theme.pxToUnit(80)};
						}
					`,
					!compact &&
						css`
							@media (min-width: ${theme.breakpoint.m}) {
								max-width: ${theme.pxToUnit(110)};
							}
						`,
				]}
			/>
			<div
				css={(theme) => css`
					display: flex;
					flex-direction: column;
					margin-left: ${theme.pxToUnit(8)};

					& > button {
						flex: 1 0 50%;
					}
				`}
			>
				<IconButton onClick={() => setNewValue(newValue + 1)} disabled={newValue === max}>
					<VisuallyHidden>+1</VisuallyHidden>
					<IconPlus />
				</IconButton>
				<IconButton onClick={() => setNewValue(newValue - 1)} disabled={newValue === min}>
					<VisuallyHidden>-1</VisuallyHidden>
					<IconMinus />
				</IconButton>
			</div>
		</div>
	);
});

export default ItemCountInput;
