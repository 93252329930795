import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	addVoucher,
	changePassword,
	completeCheckout,
	confirmCheckout,
	getCart,
	getCheckoutReview,
	getCheckoutState,
	getConfig,
	getProducts,
	getOrders,
	getResellerInfo,
	getUser,
	login,
	logout,
	lostPassword,
	onPayment,
	register,
	removeVoucher,
	requestAvailability,
	resetPassword,
	setCheckoutAddress,
	setCheckoutData,
	setCheckoutEmail,
	setItem,
	setItems,
	setPaymentMethod,
	setUseShippingAddress,
	setWishlist,
	submitResellerRequest,
	updateUser,
	setCheckoutContactData,
	getPaymentOptions,
	getHerbs,
	submitPricingRequest,
	setPromoCode,
	removePromoCode,
	getResellerProfileInfo,
} from './requests';

//
// Config
//
export const useGetConfig = () => useQuery('getConfig', getConfig);

//
// Products
//
export const useGetProducts = () => useQuery('getProducts', getProducts);

//
// Herbs
//
export const useGetHerbs = () => useQuery('getHerbs', getHerbs);

//
// User
//
export const useLoginMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(login, {
		onSuccess: () => {
			queryClient.invalidateQueries('getUser');
			queryClient.invalidateQueries('getCart');
			queryClient.invalidateQueries('resellerInfo');
		},
	});
};

export const useLogoutMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(logout, {
		onSuccess: () => {
			queryClient.invalidateQueries('getUser');
			queryClient.invalidateQueries('getCart');
			queryClient.invalidateQueries('resellerInfo');
		},
	});
};

export const useRegisterMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(register, {
		onSuccess: () => queryClient.invalidateQueries('getUser'),
	});
};

export const useSubmitResellerRequestMutation = () => useMutation(submitResellerRequest);

export const useSubmitPricingRequestMutation = () => useMutation(submitPricingRequest);

export const useUpdateUserMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(updateUser, {
		onSuccess: (data) => {
			queryClient.setQueryData(['getUser'], data);
		},
	});
};

export const useChangePasswordMutation = () => useMutation(changePassword);

export const useLostPasswordMutation = () => useMutation(lostPassword);

export const useResetPasswordMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(resetPassword, {
		onSuccess: () => {
			queryClient.invalidateQueries('getUser');
			queryClient.invalidateQueries('getCart');
		},
	});
};

export const useGetUser = () =>
	useQuery('getUser', getUser, {
		retry: false,
		// We'll refetch the user every 10 minutes if logged in to ensure the session stays alive.
		refetchInterval: (data) => (!!data ? 1000 * 60 * 10 : false), // 10 minutes
	});

export const useGetResellerProfileInfo = () => useQuery('resellerProfileInfo', getResellerProfileInfo);

// isLoggedIn ? 10 * 60 * 60 * 1000 : false, // 10 minutes

export const useGetOrders = () => useQuery('getOrders', getOrders);

//
// Cart & Wishlist
//
export const useGetCart = () =>
	useQuery('getCart', getCart, {
		placeholderData: { cart: { items: [] }, wishlist: { items: [] }, isInitialData: true },
	});

export const useSetWishlistMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setWishlist, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCart', data);
		},
	});
};

export const useSetItemMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setItem, {
		onSuccess: (data) => {
			queryClient.invalidateQueries('getCheckoutReview');
			queryClient.setQueryData('getCart', data);
		},
	});
};

export const useSetItemsMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setItems, {
		onSuccess: (data) => {
			queryClient.invalidateQueries('getCheckoutReview');
			queryClient.setQueryData('getCart', data);
		},
	});
};

export const useRequestAvailabilityMutation = () => useMutation(requestAvailability);

//
// Products
//
export const useGetResellerInfo = (makeId, options = {}) =>
	useQuery(['resellerInfo', makeId], () => getResellerInfo(makeId), options);

//
// Checkout
//
export const useGetCheckoutState = () => useQuery('getCheckoutState', getCheckoutState);

export const useSetCheckoutEmailMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setCheckoutEmail, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCheckoutState', data);
		},
	});
};

export const useSetCheckoutContactDataMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setCheckoutContactData, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCheckoutState', data);
		},
	});
};

export const useSetUseShippingAddressMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setUseShippingAddress, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCheckoutState', data);
		},
	});
};

export const useSetCheckoutAddressMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setCheckoutAddress, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCheckoutState', data);
		},
	});
};

export const useAddVoucherMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(addVoucher, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCheckoutState', data);
			queryClient.invalidateQueries('getCheckoutReview');
		},
	});
};

export const useRemoveVoucherMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(removeVoucher, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCheckoutState', data);
			queryClient.invalidateQueries('getCheckoutReview');
		},
	});
};

export const useSetCheckoutDataMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setCheckoutData, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCheckoutState', data);
		},
	});
};

export const useSetPaymentMethodMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setPaymentMethod, {
		onSuccess: (data) => {
			queryClient.setQueryData('getCheckoutState', data);
		},
	});
};

//
// Review
//
export const useGetCheckoutReview = () =>
	useQuery('getCheckoutReview', getCheckoutReview, {
		retry: false,
	});

export const useGetPaymentOptions = () => useQuery('getPaymentOptions', getPaymentOptions);

export const useConfirmCheckoutMutation = () => useMutation(confirmCheckout);

export const useCompleteCheckoutMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(completeCheckout, {
		onSuccess: () => {
			queryClient.invalidateQueries('getCart');
		},
	});
};

export const useSetPromoCodeMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(setPromoCode, {
		onSuccess: (data) => {
			if (!Object.keys(data).includes('success')) {
				queryClient.setQueryData('getCheckoutState', data);
				queryClient.invalidateQueries('getCheckoutReview');
			}
		},
	});
};

export const useRemovePromoCodeMutation = () => {
	const queryClient = useQueryClient();
	return useMutation(removePromoCode, {
		onSuccess: (data) => {
			if (!Object.keys(data).includes('success')) {
				queryClient.setQueryData('getCheckoutState', data);
				queryClient.invalidateQueries('getCheckoutReview');
			}
		},
	});
};

//
// Payment
//
export const useOnPaymentMutation = () => useMutation(onPayment);
