import { css } from '@emotion/react';

/*
 * maps {key: value} to {key:'var(--NAME-KEY)'}
 */
function applyObjToTheme(theme, obj, name, prefix = '') {
	theme[name] = {};
	Object.keys(obj).forEach((k) => {
		if (typeof obj[k] === 'object') {
			theme[name][k] = {};
			applyObjToTheme(theme[name], obj[k], k, `${prefix}${name}-`);
		} else {
			theme[name][k] = `var(--${prefix}${name}-${k})`;
		}
	});
}

/*
 * maps {key: value} to '--NAME-KEY: value
 */
function applyObjToCss(obj, name) {
	return Object.keys(obj)
		.map((k) => {
			if (typeof obj[k] === 'object') {
				return applyObjToCss(obj[k], `${name}-${k}`);
			}
			return `--${name}-${k}: ${obj[k]};`;
		})
		.join('');
}

export let theme = {};

theme.pxToEm = (px) => px / 16 + 'em';
theme.pxToRem = (px) => px / 16 + 'rem';
theme.pxToUnit = theme.pxToEm;
theme.pxToPercent = (px) => (100 / 16) * px + '%';
theme.pxToFontSize = theme.pxToPercent;

// css vars can not be used in media queries
theme.breakpoint = {
	mobileNav: theme.pxToEm(1024),

	xxs: theme.pxToEm(375),
	xs: theme.pxToEm(500),
	s: theme.pxToEm(640),
	m: theme.pxToEm(768),
	l: theme.pxToEm(1024),
	ml: theme.pxToEm(1280),
	xl: theme.pxToEm(1920),
};

const dimension = {
	container: {
		padding: { s: '0 ' + theme.pxToEm(6), m: '0 ' + theme.pxToEm(20), l: '0 ' + theme.pxToEm(20) },
		margin: { s: theme.pxToEm(20), m: theme.pxToEm(20), l: theme.pxToEm(30) },
	},
	spacing: {
		s: theme.pxToEm(40),
		m: theme.pxToEm(40),
		l: theme.pxToEm(60),
	},
	gap: {
		s: theme.pxToEm(2),
		m: theme.pxToEm(2),
		l: theme.pxToEm(2),
	},
	maxWidth: theme.pxToEm(2000),
	maxWidthNarrow: theme.pxToEm(1040),
	header: {
		height: {
			s: theme.pxToEm(90),
			m: theme.pxToEm(120),
			l: theme.pxToEm(200),
		},
		logoWidth: {
			s: theme.pxToEm(154),
			m: theme.pxToEm(220),
			l: theme.pxToEm(275),
		},
	},
	element: {
		margin: {
			top: {
				s: theme.pxToEm(20),
				m: theme.pxToEm(40),
				l: theme.pxToEm(60),
			},
			bottom: {
				s: theme.pxToEm(10),
				m: theme.pxToEm(20),
				l: theme.pxToEm(30),
			},
		},
	},
};
applyObjToTheme(theme, dimension, 'dimension');

const zIndex = {
	header: 100,
	nav: 101,
	headerNav: 102,
	cartNotification: 105,
	lightbox: 200,
	overlay: 250,
	overlayNotification: 255,
	loadingScreen: 300,
	consent: 400,
};
applyObjToTheme(theme, zIndex, 'zIndex');

const font = {
	default: {
		family: '"museo-slab", serif',
		weight: {
			thin: 200,
			regular: 400,
			bold: 800,
		},
		size: {
			s: {
				s: theme.pxToPercent(12),
				m: theme.pxToPercent(14),
				l: theme.pxToPercent(15),
			},
			m: {
				s: theme.pxToPercent(14),
				m: theme.pxToPercent(16),
				l: theme.pxToPercent(17),
			},
			l: {
				s: theme.pxToPercent(15),
				m: theme.pxToPercent(18),
				l: theme.pxToPercent(21),
			},
			xl: {
				s: theme.pxToPercent(20),
				m: theme.pxToPercent(20),
				l: theme.pxToPercent(36),
			},
			xxl: {
				s: theme.pxToPercent(30),
				m: theme.pxToPercent(40),
				l: theme.pxToPercent(50),
			},
			nav: {
				s: theme.pxToPercent(36),
				m: theme.pxToPercent(45),
				l: theme.pxToPercent(70),
			},
			navSmall: {
				s: theme.pxToPercent(20),
				m: theme.pxToPercent(25),
				l: theme.pxToPercent(42),
			},
		},
		lineHeight: {
			s: {
				s: 1.25,
				m: 1.25,
				l: 1.25,
			},
			m: {
				s: 18 / 14,
				m: 21 / 16,
				l: 24 / 17,
			},
			l: {
				s: 21 / 15,
				m: 25 / 18,
				l: 35 / 25,
			},
			xl: {
				s: 28 / 20,
				m: 32 / 25,
				l: 52 / 42,
			},
			xxl: {
				s: 25.5 / 20,
				m: 32 / 25,
				l: 52 / 42,
			},
			nav: {
				s: 1.2,
				m: 1.14,
				l: 1.14,
			},
			navSmall: {
				s: 34 / 20,
				m: 40 / 25,
				l: 62 / 42,
			},
		},
	},
};
font.alternate = { ...font.default, family: 'monospace' };
applyObjToTheme(theme, font, 'font');
theme.typography = {
	getStyle: ({ theme, size = 'm', weight = 'regular', font = 'default', style = 'normal', decoration = 'initial' }) => {
		return css`
			font-family: ${theme.font[font].family};
			font-size: ${theme.font[font].size[size].s};
			font-weight: ${theme.font[font].weight[weight]};
			font-style: ${style};
			line-height: ${theme.font[font].lineHeight[size].s};
			text-decoration: ${decoration};

			@media (min-width: ${theme.breakpoint.m}) {
				font-size: ${theme.font[font].size[size].m};
				line-height: ${theme.font[font].lineHeight[size].m};
			}

			@media (min-width: ${theme.breakpoint.l}) {
				font-size: ${theme.font[font].size[size].l};
				line-height: ${theme.font[font].lineHeight[size].l};
			}
		`;
	},
};

export const colorPalettes = {};
colorPalettes.light = {
	fg: '#000',
	bg: '#fff',

	fgContrast: 'var(--color-bg)',
	bgContrast: 'var(--color-fg)',

	grey1: '#F4F4F4',
	grey2: '#d9d9d9',
	grey3: '#a8a8a8',
	grey4: '#3e3e3e',

	grey1Contrast: 'var(--color-fg)',
	grey2Contrast: 'var(--color-fg)',
	grey3Contrast: 'var(--color-bg)',
	grey4Contrast: 'var(--color-bg)',

	primary: '#36C622',
	primaryDark: '#39AB31',
	primaryLight: '#49CF3C',
	primaryExtraDark: '#61B648',

	primaryContrast: '#fff',
	primaryDarkContrast: '#fff',
	primaryLightContrast: '#fff',
	primaryExtraDarkContrast: '#fff',

	secondary: '#892835',
	secondaryDark: '#892835',
	secondaryLight: '#892835',

	secondaryContrast: '#fff',
	secondaryDarkContrast: '#fff',
	secondaryLightContrast: '#fff',

	accent: '#00C9B3',
	accentDark: '#169D8B',

	accentContrast: '#fff',
	accentDarkContrast: '#fff',

	overlayBackground: '#C03C64',
	overlayBackgroundContrast: 'var(--color-bg)',

	loadingBackground: 'rgba(255, 255, 255, .4)',

	selection: 'var(--color-secondaryContrast)',
	selectionBackground: 'var(--color-secondary)',

	success: '#3b9e3b',
	successContrast: '#fff',

	warning: '#ed8a15',
	warningContrast: '#000',

	error: '#ef1448',
	errorContrast: '#fff',

	buttonBg: 'var(--color-bg)',
	buttonFg: 'var(--color-bgContrast)',
	buttonBgActive: 'var(--color-grey2)',
	buttonFgActive: 'var(--color-grey4)',

	footerBg: '#F7F7F7',
	footerFg: '#000',
};

// only overwrites
colorPalettes.dark = {
	fg: '#F2F2F2',
	bg: '#051900',

	fgContrast: 'var(--color-bg)',
	bgContrast: 'var(--color-fg)',

	grey4: '#e3e3e3',
	grey3: '#d9d9d9',
	grey2: '#a8a8a8',
	grey1: '#3e3e3e',

	grey4Contrast: 'var(--color-bg)',
	grey3Contrast: 'var(--color-bg)',
	grey2Contrast: 'var(--color-fg)',
	grey1Contrast: 'var(--color-fg)',

	primary: '#36C622',
	primaryDark: '#39AB31',
	primaryLight: '#49CF3C',
	primaryExtraDark: '#61B648',

	primaryContrast: '#fff',
	primaryDarkContrast: '#fff',
	primaryLightContrast: '#fff',
	primaryExtraDarkContrast: '#fff',

	secondary: '#892835',
	secondaryDark: '#892835',
	secondaryLight: '#892835',

	secondaryContrast: '#fff',
	secondaryDarkContrast: '#fff',
	secondaryLightContrast: '#fff',

	accent: '#00C9B3',
	accentDark: '#169D8B',

	accentContrast: '#fff',
	accentDarkContrast: '#fff',

	overlayBackground: '#C03C64',
	overlayBackgroundContrast: 'var(--color-fg)',

	loadingBackground: 'rgba(0, 0, 0, .4)',

	buttonBg: 'var(--color-fg)',
	buttonFg: 'var(--color-fgContrast)',
	buttonBgActive: 'var(--color-grey2)',
	buttonFgActive: 'var(--color-grey1)',

	footerBg: '#D5D5D5',
	footerFg: '#000',
};

applyObjToTheme(theme, colorPalettes.light, 'color');

theme.getColors = ({ theme, key, inversed = false }) => css`
	color: ${theme.color[key + (inversed ? 'Contrast' : '')]};
	background: ${theme.color[key + (!inversed ? 'Contrast' : '')]};
`;

theme = {
	...theme,

	resetButton: css`
		cursor: pointer;
		appearance: none;
		font: inherit;
		background: transparent;
		color: inherit;
		border: 0;
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border-radius: 0;

		&::-moz-focus-inner {
			border: 0;
		}

		&:active,
		&:focus {
			outline: none;
		}

		&:disabled {
			cursor: default;
		}
	`,

	headerButton: ({ theme }) => [
		theme.resetButton,
		theme.typography.getStyle({ theme }),
		css`
			pointer-events: all;
			display: inline-flex;
			align-items: center;
			border-radius: 9999px;
			padding: ${6 / 14}em 1em;
			font-weight: bold;
			cursor: pointer;
			background-color: var(--color-buttonBg);
			color: var(--color-buttonFg);

			&:not(:disabled) {
				&:hover,
				&:focus {
					background-color: var(--color-buttonBgActive);
				}

				&:active {
					background-color: var(--color-buttonBgActive);
					color: var(--color-buttonFgActive);
				}
			}

			@media (min-width: ${theme.breakpoint.m}) {
				padding: ${11 / 16}em 1em;
			}

			@media (min-width: ${theme.breakpoint.l}) {
				padding: ${11 / 17}em 1em;
			}
		`,
	],
	button: ({ theme, variant = 'filled', large = false }) => [
		theme.resetButton,
		theme.typography.getStyle({ theme, size: large ? 'l' : 'm' }),
		css`
			display: inline-flex;
			align-items: center;
			border-radius: 9999px;
			padding: ${11 / 14}em ${14 / 14}em;
			font-weight: bold;
			cursor: pointer;

			&:disabled {
				filter: grayscale(0.5);
			}

			@media (min-width: ${theme.breakpoint.m}) {
				padding: ${10 / 16}em ${25 / 16}em;
			}

			@media (min-width: ${theme.breakpoint.l}) {
				padding: ${13 / 17}em ${28 / 17}em;
			}
		`,
		variant === 'flat' &&
			css`
				padding: 0;
				@media (min-width: ${theme.breakpoint.m}) {
					padding: 0.6em;
				}

				@media (min-width: ${theme.breakpoint.l}) {
					padding: 0.6em;
				}
			`,
		variant === 'filled' &&
			css`
				background-color: var(--color-primary);
				color: var(--color-primaryContrast);

				&:not(:disabled) {
					&:hover,
					&:focus {
						background-color: var(--color-primaryLight);
						color: var(--color-primaryLightContrast);
					}
					&:active {
						background-color: var(--color-primaryDark);
						color: var(--color-primaryDarkContrast);
					}
				}
			`,
		variant === 'white' &&
			css`
				background-color: #fff;
				color: #000;

				&:not(:disabled) {
					&:hover,
					&:focus {
						background-color: var(--color-buttonBgActive);
					}

					&:active {
						background-color: var(--color-buttonBgActive);
						color: var(--color-buttonFgActive);
					}
				}
			`,
		variant === 'outlined' &&
			css`
				background-color: transparent;
				color: var(--color-primary);
				border: 1px solid var(--color-primary);

				&:not(:disabled) {
					&:hover,
					&:focus {
						border-color: var(--color-primaryLight);
						background-color: var(--color-primaryLight);
						color: var(--color-primaryLightContrast);
					}

					&:active {
						border-color: var(--color-primaryDark);
						background-color: var(--color-primaryDark);
						color: var(--color-primaryDarkContrast);
					}
				}
			`,
	],

	row: ({ theme }) => css`
		margin: calc(${theme.dimension.gap.s} / -2);

		@media (min-width: ${theme.breakpoint.m}) {
			margin: calc(${theme.dimension.gap.m} / -2);
		}

		@media (min-width: ${theme.breakpoint.l}) {
			margin: calc(${theme.dimension.gap.l} / -2);
		}
	`,

	col: ({ theme }) => css`
		margin: calc(${theme.dimension.gap.s} / 2);

		@media (min-width: ${theme.breakpoint.m}) {
			margin: calc(${theme.dimension.gap.m} / 2);
		}

		@media (min-width: ${theme.breakpoint.l}) {
			margin: calc(${theme.dimension.gap.l} / 2);
		}
	`,

	container: ({ theme, limit = true, narrow = false, padding = true, margin = false }) => [
		limit
			? css`
					max-width: ${narrow ? theme.dimension.maxWidthNarrow : theme.dimension.maxWidth};
					margin-left: auto;
					margin-right: auto;
			  `
			: null,
		padding
			? css`
					box-sizing: border-box;
					padding: ${theme.dimension.container.padding.s};

					@media (min-width: ${theme.breakpoint.m}) {
						padding: ${theme.dimension.container.padding.m};
					}

					@media (min-width: ${theme.breakpoint.l}) {
						padding: ${theme.dimension.container.padding.l};
					}
			  `
			: null,
		margin
			? css`
					margin-top: ${theme.dimension.container.margin.s};
					margin-bottom: ${theme.dimension.container.margin.s};

					@media (min-width: ${theme.breakpoint.m}) {
						margin-top: ${theme.dimension.container.margin.m};
						margin-bottom: ${theme.dimension.container.margin.m};
					}

					@media (min-width: ${theme.breakpoint.l}) {
						margin-top: ${theme.dimension.container.margin.l};
						margin-bottom: ${theme.dimension.container.margin.l};
					}
			  `
			: null,
	],

	createPadding: ({ theme, s = '1em', m = '1.4em', l = '2em' }) => css`
		margin: ${s};

		@media (min-width: ${theme.breakpoint.m}) {
			margin: ${m};
		}

		@media (min-width: ${theme.breakpoint.l}) {
			margin: ${l};
		}
	`,

	createMargin: ({ theme, s = '1em', m = '1.4em', l = '2em' }) => css`
		margin: ${s};

		@media (min-width: ${theme.breakpoint.m}) {
			margin: ${m};
		}

		@media (min-width: ${theme.breakpoint.l}) {
			margin: ${l};
		}
	`,

	createGap: ({ theme, s, m, l }) => css`
		gap: ${(s !== undefined && s) || theme.dimension.gap.s};

		@media (min-width: ${theme.breakpoint.m}) {
			gap: ${(m !== undefined && m) || theme.dimension.gap.m};
		}

		@media (min-width: ${theme.breakpoint.l}) {
			gap: ${(l !== undefined && l) || theme.dimension.gap.l};
		}
	`,
};

export const globalStylesDark = css`
	${applyObjToCss(colorPalettes.dark, 'color')}
`;

export const globalStyles = [
	css`
		:root {
			${applyObjToCss(colorPalettes.light, 'color')}
			${applyObjToCss(zIndex, 'zIndex')}
			${applyObjToCss(dimension, 'dimension')}
			${applyObjToCss(font, 'font')}
		}

		html,
		body {
			height: 100%;
			padding: 0;
			margin: 0;
			font-size: 100%;
			font-family: ${theme.font.default.family};
			font-weight: ${theme.font.default.weight.regular};
			font-style: normal;
			line-height: 1.2;
		}

		body {
			color: ${theme.color.fg};
			background-color: ${theme.color.bg};
			-moz-osx-font-smoothing: grayscale;
			font-size-adjust: none;
			-webkit-text-size-adjust: none;
			box-sizing: border-box;
			font-size: 100%;

			& * {
				box-sizing: border-box;
			}
		}

		::selection {
			background: ${theme.color.selectionBackground};
			color: ${theme.color.selection};
			text-shadow: none;
		}

		a {
			color: inherit;
		}
	`,
];

export default theme;
