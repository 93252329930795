/** @jsxImportSource @emotion/react */
import { Global } from '@emotion/react';
import { Fragment, useMemo, useRef, useCallback } from 'react';
import Slider from 'react-slick';

import styles from './styles';
import Arrow from './Arrow';
import ArrowedDots from './ArrowedDots';
import Dots from './Dots';
import Dot from './Dot';

const defaultSettings = {
	infinite: true,
	speed: 500,

	//slidesToShow: 1,
	//slidesToScroll: 1,

	arrows: true,
	dots: true,

	customPaging: (i) => <Dot i={i} />,
	appendDots: (dots) => <Dots>{dots}</Dots>,
};

const Slick = (props) => {
	const slider = useRef();

	const next = useCallback(() => {
		slider?.current?.slickNext();
	}, [slider]);

	const prev = useCallback(() => {
		slider?.current?.slickPrev();
	}, [slider]);

	const settings = useMemo(() => {
		const ret = {
			...defaultSettings,
			...props,
		};
		if (ret.arrows) {
			if (ret.dots) {
				ret.arrows = false;
				ret.appendDots = (dots) => <ArrowedDots dots={dots} next={next} prev={prev} />;
			} else {
				ret.nextArrow = <Arrow dir={1} />;
				ret.prevArrow = <Arrow dir={-1} />;
			}
		}
		return ret;
	}, [props, next, prev]);

	return (
		<Fragment>
			<Global styles={styles} />
			<Slider ref={slider} {...settings} />
		</Fragment>
	);
};

export default Slick;
