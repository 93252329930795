/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AnimatePresence } from 'framer-motion';
import { useCallback } from 'react';
import useViewState from '../../hooks/useViewState';
import OverlayRoot from '../overlay/OverlayRoot';
import OverlayHeader from '../overlay/OverlayHeader';
import LoginOverlay from './login/LoginOverlay';
import LostPasswordOverlay from './password/LostPasswordOverlay';
import ResellerRegisterOverlay from './reseller-register/ResellerRegisterOverlay';
import RegisterOverlay from './register/RegisterOverlay';
import ProductOverlay from './product/ProductOverlay';
import { useTranslation } from 'react-i18next';

const AuthOverlayContent = ({ children, type }) => {
	const closeAll = useViewState(useCallback((state) => state.closeAll, []));
	const { t } = useTranslation();
	return (
		<OverlayRoot
			aria-label={t([`overlay.auth.${type}`, 'overlay.unspecific'])}
			css={css`
				background: var(--color-overlayBackground);
				color: var(--color-overlayBackgroundContrast);
				--color-error: var(--color-overlayBackgroundContrast);
			`}
			header={<OverlayHeader onClose={() => closeAll()} />}
		>
			{children}
		</OverlayRoot>
	);
};

const ShopOverlays = () => {
	const authOverlay = useViewState(useCallback((state) => state.authOverlay, []));
	const productOverlay = useViewState(useCallback((state) => state.productOverlay, []));

	return (
		<AnimatePresence>
			{!!authOverlay && (
				<AuthOverlayContent>
					{authOverlay === 'login' && <LoginOverlay />}
					{authOverlay === 'register' && <RegisterOverlay />}
					{authOverlay === 'reseller-register' && <ResellerRegisterOverlay />}
					{authOverlay === 'lostpassword' && <LostPasswordOverlay />}
				</AuthOverlayContent>
			)}
			{!!productOverlay && <ProductOverlay />}
		</AnimatePresence>
	);
};

export default ShopOverlays;
