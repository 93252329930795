/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import Container from '../layout/Container';
import Logo from '../layout/Logo';
import OverlayButton from './OverlayButton';

const OverlayHeader = ({ label, onClose = () => {}, ...props }) => {
	const { t } = useTranslation();
	return (
		<div
			css={(theme) => css`
				display: flex;
				align-items: center;

				height: ${theme.dimension.header.height.s};

				@media (min-width: ${theme.breakpoint.m}) {
					height: ${theme.dimension.header.height.m};
				}

				@media (min-width: ${theme.breakpoint.l}) {
					height: ${theme.dimension.header.height.l};
					align-items: flex-start;
					padding-top: ${theme.pxToUnit(30)};
				}
			`}
			{...props}
		>
			<Container
				margin={false}
				limit={false}
				css={(theme) => css`
					flex: 1 1 auto;
					display: flex;
					align-items: center;
					@media (min-width: ${theme.breakpoint.l}) {
						align-items: flex-start;
					}
				`}
			>
				<div
					css={css`
						flex: 1 1 50%;
					`}
				></div>
				<div
					css={(theme) => css`
						display: flex;
						align-items: center;
						flex: 0 0 auto;
						@media (min-width: ${theme.breakpoint.mobileNav}) {
							align-items: flex-start;
							padding-top: ${theme.pxToUnit(12)};
						}
					`}
				>
					<Logo
						css={(theme) => css`
							width: ${theme.dimension.header.logoWidth.s};

							@media (min-width: ${theme.breakpoint.l}) {
								width: ${theme.dimension.header.logoWidth.m};
							}

							@media (min-width: ${theme.breakpoint.ml}) {
								width: ${theme.dimension.header.logoWidth.l};
							}
						`}
					/>
				</div>
				<div
					css={css`
						display: flex;
						align-items: center;
						justify-content: flex-end;
						flex: 1 1 50%;
					`}
				>
					<OverlayButton label={label || t('overlay.close')} onClick={onClose} />
				</div>
			</Container>
		</div>
	);
};

export default OverlayHeader;
