/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useState, useRef } from 'react';
import { motion } from 'framer-motion';

import { useImageLoader } from '.';
import Lightbox from './Lightbox';

const Image = ({ id, title, aspect, thumb, customAspect, fit, focus, noLightbox, noThumb, noBackground, ...props }) => {
	const { ref, src } = useImageLoader({ id, aspect });
	const imgRef = useRef();
	const [isLightbox, setIsLightbox] = useState(false);

	return (
		<div
			ref={ref}
			css={css`
				position: relative;
				width: 100%;
				height: 0;
				padding-bottom: ${Math.floor(100.0 * (customAspect || aspect))}%;
				background: ${noBackground || src ? 'transparent' : '#ccc '};
				background-size: cover;
			`}
			{...props}
		>
			{src ? (
				<motion.img
					ref={imgRef}
					src={src}
					alt={title}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					role={!noLightbox ? 'button' : null}
					css={(theme) => [
						css`
							cursor: ${noLightbox ? 'inherit' : 'zoom-in'};
							z-index: 2;
							position: absolute;
							width: 100%;
							height: 100%;
						`,
						!noLightbox &&
							css`
								&:focus,
								&:active {
									outline: 4px solid ${theme.color.linkActive};
								}
							`,
						!!fit &&
							css`
								object-fit: ${fit};
							`,
					]}
					onClick={
						!noLightbox
							? () => {
									setIsLightbox(true);
							  }
							: null
					}
				/>
			) : (
				!noThumb && (
					<motion.img
						ref={imgRef}
						src={thumb}
						alt=""
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 1 }}
						css={[
							css`
								position: absolute;
								width: 100%;
								height: 100%;
							`,
							!!fit &&
								css`
									object-fit: ${fit};
								`,
						]}
					/>
				)
			)}
			{!noLightbox && !!imgRef.current && (
				<Lightbox
					active={isLightbox}
					node={imgRef.current}
					rect={imgRef.current.getBoundingClientRect()}
					image={{ id, thumb: src, aspect }}
					onClose={() => setIsLightbox(false)}
				/>
			)}
		</div>
	);
};

export default memo(Image);
